import { Box, Card, Typography } from "@mui/joy";

const Statistics = () => {
  return (
    <>
      <Box
        className="w-full flex flex-grow flex-col overflow-y-auto"
        sx={{
          padding: { xs: 1, md: 3 },
          backgroundColor: "background.default",
        }}
      >
        <Card
          sx={{
            mr: "auto",
            ml: { xs: "auto", md: 0 },
            mt: { xs: 2, md: 0 },
            p: 3,
            gap: { xs: 2, md: 5 },
            flexDirection: "row",
          }}
        >
          <Typography level="h4" align="center" sx={{ marginBottom: "0rem" }}>
            {`Work in progress...`}
          </Typography>
        </Card>
      </Box>
    </>
  );
};

export default Statistics;
