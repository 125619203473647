import ActionDropdown from "../partials/ActionDropdown";
import {
  Share as ShareIcon,
  Print as PrintIcon,
  FileDownload as FileDownloadIcon,
} from "@mui/icons-material";
import toastr from "toastr";

import PDFIcon from "../../icons/PDFIcon";
import CSVIcon from "../../icons/CSVIcon";
import { downloadAsCSV } from "../../handlers/filesHandler";

const showSaveFileDialog = (data, filename, type) => {
  const file = new Blob([data], { type: type });

  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(file, filename);
  } else {
    const a = document.createElement("a");
    const url = URL.createObjectURL(file);
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
  }
};

const ExportItemsMenu = ({ itemType, hasItems }) => {
  return (
    <ActionDropdown
      actions={[
        {
          icon: <CSVIcon />,
          name: "Als CSV herunterladen",
          onClick: async () => {
            return downloadAsCSV(itemType)
              .then((response) => {
                if (response.success) {
                  showSaveFileDialog(
                    response.fileContents,
                    "export.csv",
                    "text/csv",
                  );
                } else {
                  toastr.error(response.error);
                }
              })
              .catch((error) => toastr.error(error));
          },
          hidden: !hasItems,
        },
        { icon: <PDFIcon />, name: "Als PDF herunterladen", hidden: !hasItems },
        { icon: <PrintIcon />, name: "Drucken", hidden: !hasItems },
        { icon: <ShareIcon />, name: "Teilen", hidden: !hasItems },
        { icon: <FileDownloadIcon />, name: "Importieren" },
      ]}
    />
  );
};

export default ExportItemsMenu;
