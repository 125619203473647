import {
  Assessment as AssessmentIcon,
  Home as HomeIcon,
  HomeWork as HomeWorkIcon,
  LocationOn as LocationOnIcon,
  LogoutRounded as LogoutRoundedIcon,
  SchoolRounded as SchoolRoundedIcon,
  Settings as SettingsIcon,
} from "@mui/icons-material";

import FlightsOverview from "../components/content/flight/Overview";
import FlightDetails from "../components/content/flight/Details";
import Gliders from "../components/content/Gliders";
import Home from "../components/content/Home";
import Locations from "../components/content/Locations";
import Settings from "../components/content/Settings";
import Statistics from "../components/content/Statistics";

import FlightSchools from "../components/content/admin/FlightSchools";
import TeacherView from "../components/content/teacher/TeacherView";

import Login from "../auth/Login";
import Logout from "../auth/Logout";
import Register from "../auth/Register";

import FlightSchool from "../components/content/admin/FlightSchool";
import GliderIcon from "../icons/GliderIcon";
import RouteIcon from "../icons/RouteIcon";

export const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/register", component: <Register /> },
];

export const privateRoutes = [
  { path: "/", name: "Home", component: <Home />, icon: <HomeIcon /> },
  {
    path: "/flights",
    name: "Flüge",
    component: <FlightsOverview />,
    icon: <RouteIcon />,
  },
  {
    path: "/flights/:id",
    component: <FlightDetails />,
  },
  {
    path: "/gliders",
    name: "Gleiter",
    component: <Gliders />,
    icon: <GliderIcon />,
  },
  {
    path: "/locations",
    name: "Spots",
    component: <Locations />,
    icon: <LocationOnIcon />,
  },
  {
    path: "/statistics",
    name: "Statistiken",
    component: <Statistics />,
    icon: <AssessmentIcon />,
  },
];

export const userRoutes = [
  {
    path: "/settings",
    name: "Einstellungen",
    component: <Settings />,
    icon: <SettingsIcon />,
  },
  {
    path: "/logout",
    name: "Abmelden",
    component: <Logout />,
    icon: <LogoutRoundedIcon />,
  },
];

export const teacherRoutes = [
  {
    path: "/teacherView",
    name: "Lehreransicht",
    component: <TeacherView />,
    icon: <SchoolRoundedIcon />,
  },
];

export const adminRoutes = [
  {
    path: "/flightSchools",
    name: "Flugschulen",
    component: <FlightSchools />,
    icon: <HomeWorkIcon />,
  },
  {
    path: "/flightSchools/:flightSchoolId",
    component: <FlightSchool />,
  },
];
