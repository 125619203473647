import React from "react";
import ReactDOM from "react-dom/client";

import User from "./contexts/User";
import App from "./App";
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <User>
    <App />
  </User>,
);
