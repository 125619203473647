import APIHandler from "./apiHandler";

export async function getFlights() {
  return APIHandler.get({ path: "/flights" });
}
export async function getFlight(id) {
  return APIHandler.get({ path: `/flights/${id}` });
}
export async function createFlight(flight) {
  return APIHandler.post({ path: "/flights", data: flight });
}
export async function updateFlight(id, flight) {
  return APIHandler.put({ path: `/flights/${id}`, data: flight });
}
export async function deleteFlight(id) {
  return APIHandler.delete({ path: `/flights/${id}` });
}
