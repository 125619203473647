import { Box, Button, Card, TextField, Typography } from "@mui/joy";
import { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import toastr from "toastr";

import { loginUser } from "../handlers/user";

import CookieConsent from "react-cookie-consent";
//import CookieIcon from "@mui/icons-material/Cookie";

const Login = () => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    const { email, password } = Object.fromEntries(
      new FormData(e.target).entries(),
    );

    setLoading(true);
    await loginUser({ email, password })
      .then((response) => {
        if (response.success) {
          toastr.success(response.message || "Login erfolgreich");
        } else {
          toastr.error(response.error || "Login fehlgeshclagen");
        }
        setLoading(false);
      })
      .catch((error) =>
        toastr.error(error || error.message || "Login fehlgeschlagen"),
      );
  }, []);

  return (
    <>
      <CookieConsent
        location="bottom"
        buttonText="Verstanden"
        cookieName="consentCookie"
        style={{
          position: "absolute",
          background: "#262B40",
          left: "auto",
          right: "auto",
        }}
        buttonStyle={{
          color: "white",
          fontSize: "13px",
          border: "1px solid white",
          backgroundColor: "transparent",
        }}
        expires={150}
      >
        Unsere Website verwendet Cookies, um Ihnen ein optimales
        Benutzererlebnis zu bieten. Durch die Nutzung unserer Website stimmen
        Sie der Verwendung von Cookies zu. Wir verwenden Cookies ausschließlich
        für technische Zwecke, um die Funktionsfähigkeit unserer Website zu
        gewährleisten und Ihnen eine reibungslose Navigation zu ermöglichen.
      </CookieConsent>
      <Box
        className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center"
        sx={{ backgroundColor: "background.default" }}
      >
        <Card
          sx={{
            padding: 4,
            width: {
              xs: "80%",
              sm: "70%",
              md: "50%",
              lg: "30%",
            },
          }}
        >
          <form onSubmit={handleSubmit} className="flex flex-col gap-4">
            <Typography level={"h4"} align="center">
              Anmelden
            </Typography>
            <TextField
              label="Email"
              name="email"
              type="email"
              variant="outlined"
              required
            />
            <TextField
              label="Passwort"
              name="password"
              type="password"
              variant="outlined"
              required
            />
            <Button type="submit" loading={loading} className="w-full">
              Anmelden
            </Button>
            <Link to="/register">
              <Typography
                align="center"
                level="body2"
                sx={{
                  ":hover": {
                    color: "primary.main",
                  },
                }}
              >
                Sie haben noch keinen Account?
              </Typography>
            </Link>
          </form>
        </Card>
      </Box>
    </>
  );
};

export default Login;
