import { useContext, useEffect } from "react";
import LoadingSpinner from "../components/helpers/LoadingSpinner";
import { UserContext } from "../contexts/User";

const Logout = () => {
  const { logout } = useContext(UserContext);

  useEffect(() => {
    logout();
  }, [logout]);

  return <LoadingSpinner />;
};

export default Logout;
