import APIHandler from "./apiHandler";

export async function getGliders() {
  return APIHandler.get({ path: "/gliders" });
}
export async function getGlider(id) {
  return APIHandler.get({ path: `/gliders/${id}` });
}
export async function createGlider(glider) {
  return APIHandler.post({ path: "/gliders", data: glider });
}
export async function updateGlider(id, glider) {
  return APIHandler.put({ path: `/gliders/${id}`, data: glider });
}
export async function deleteGlider(id) {
  return APIHandler.delete({ path: `/gliders/${id}` });
}
