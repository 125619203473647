import { Edit as EditIcon } from "@mui/icons-material";
import { Box, Button, Card, Typography } from "@mui/joy";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import toastr from "toastr";
import moment from "moment";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

import LoadingSpinner from "../../helpers/LoadingSpinner";
import { getFlight } from "../../../handlers/flight";
import FlightModal from "./Modal";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

const maxBatches = 45;
const formatGPSData = (gpsData) => {
  if (gpsData.length <= maxBatches)
    return gpsData.map((item) => ({ timestamp: item.t, altitude: item.y }));

  const firstTimestamp = gpsData[0].t;
  const lastTimestamp = gpsData[gpsData.length - 1].t;
  const timeDiff = lastTimestamp - firstTimestamp;

  const batches = [];

  for (let i = 0; i < maxBatches; i++) {
    const batchStart = firstTimestamp + (timeDiff / maxBatches) * i;
    const batchEnd = firstTimestamp + (timeDiff / maxBatches) * (i + 1);

    const batch = gpsData.filter((item) => {
      return item.t >= batchStart && item.t < batchEnd;
    });

    batches.push({
      timestamp: parseInt(
        batch.map((b) => b.t).reduce((a, b) => a + b, 0) / batch.length,
      ),
      altitude: parseInt(
        batch.map((b) => b.y).reduce((a, b) => a + b, 0) / batch.length,
      ),
    });
  }

  return batches;
};

const FlightDetails = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const { id } = useParams();
  const [flightLoading, setFlightLoading] = useState(false);
  const [flight, setFlight] = useState(null);
  const [gpsData, setGpsData] = useState([]);

  const loadFlight = useCallback(async () => {
    setFlightLoading(true);
    return getFlight(id)
      .then((response) => {
        if (response.success) {
          const { flight } = response;
          setFlight(flight);
          if (flight.gpsData && flight.gpsData.length > 0) {
            setGpsData(formatGPSData(response.flight.gpsData));
          }
        } else {
          toastr.error(response.error);
        }
      })
      .catch((error) => toastr.error(error))
      .finally(() => setFlightLoading(false));
  }, [id]);

  useEffect(() => {
    loadFlight();
  }, [id, loadFlight]);

  return flightLoading ? (
    <LoadingSpinner />
  ) : (
    <>
      <Box
        className="w-full flex flex-grow flex-col overflow-y-auto"
        sx={{
          padding: { xs: 2, md: 3 },
          backgroundColor: "background.default",
        }}
      >
        <Box className="flex justify-between items-center">
          <Card
            sx={{
              p: 3,
              gap: { xs: 2, sm: 3, md: 5 },
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Typography
              className="text-start"
              level="h4"
              sx={{ fontSize: "xl", fontWeight: "md" }}
            >
              Flug Übersicht
            </Typography>
            <Button
              variant="soft"
              endDecorator={<EditIcon />}
              color="info"
              onClick={() => setModalOpen(true)}
            >
              Bearbeiten
            </Button>
          </Card>
        </Box>
        <Box>
          <Card sx={{ my: "1.5rem" }}>
            <Box className="flex gap-3">
              <Box className="flex-grow">
                <Line
                  options={{
                    responsive: true,
                    scales: {
                      x: {
                        title: {
                          display: true,
                          text: "Zeit",
                        },
                      },
                      y: {
                        title: {
                          display: true,
                          text: "Höhenmeter",
                        },
                      },
                    },
                  }}
                  data={{
                    labels: gpsData.map((item) =>
                      moment(item.timestamp).format("HH:mm"),
                    ),
                    datasets: [
                      {
                        label: "Höhenmeter",
                        backgroundColor: "#fb2880",
                        borderColor: "#fb2880",
                        data: gpsData.map((item) => item.altitude),
                        tension: 0.4,
                      },
                    ],
                  }}
                />
              </Box>
              <Box className="w-1/2 flex items-center justify-center">
                google maps
              </Box>
            </Box>
          </Card>
        </Box>
      </Box>
      <FlightModal
        open={modalOpen}
        flight={flight}
        handleClose={() => {
          setModalOpen(false);
          loadFlight();
        }}
      />
    </>
  );
};

export default FlightDetails;
