import APIHandler from "./apiHandler";

export async function getLocations() {
  return APIHandler.get({ path: "/locations" });
}
export async function getLocation(id) {
  return APIHandler.get({ path: `/locations/${id}` });
}
export async function createLocation(glider) {
  return APIHandler.post({ path: "/locations", data: glider });
}
export async function updateLocation(id, glider) {
  return APIHandler.put({ path: `/locations/${id}`, data: glider });
}
export async function deleteLocation(id) {
  return APIHandler.delete({ path: `/locations/${id}` });
}
