import { Option, Select } from "@mui/joy";
import { FormGroup } from "@mui/material";
import { useCallback } from "react";

const sessions = {
  Admin: "EC53A842DE747EF1EA9AF877180CF6C7C1D234DD39D49877902EB2D28DBA32A0",
  Schule: "50384E54BBAEF0C66D0247743ADBB846D5708A30436439FCFE80D3691D987483",
  Lehrer: "8DABB583AF7A4A1E011E2252E029FE36A857785B0D70A59C59F022123B1EF549",
  Schüler: "1A729AB4E8BCCB92CCB162C4BB96AC8A3DC4E459FC6A6DBD2EBD741F12E1362A",
  Extern: "26723548DFE24BE3CAD18A16DC8B97A6FCB6B752A34562F7CB385455ACD11D96",
};

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
const sessionCookie = getCookie("session");

const RoleSelector = () => {
  const domain = window.location.hostname;

  const handleChange = useCallback(
    (e) => {
      if (domain === "localhost") {
        document.cookie = `session=${sessions[e.target.outerText]}`;
        window.location.reload();
      }
    },
    [domain],
  );

  return (
    domain === "localhost" && (
      <FormGroup>
        <label htmlFor="role-selector" className="text-white font-">
          Role
        </label>
        <Select
          placeholder="Auswählen..."
          slotProps={{
            button: {
              id: "role-selector",
            },
          }}
          value={
            Object.keys(sessions).find(
              (key) => sessions[key] === sessionCookie,
            ) || ""
          }
          onChange={handleChange}
        >
          {Object.keys(sessions).map((role, i) => (
            <Option value={role} key={i}>
              {role}
            </Option>
          ))}
        </Select>
      </FormGroup>
    )
  );
};

export default RoleSelector;
