import { Add as AddIcon, Close as CloseIcon } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  IconButton,
  Modal,
  ModalDialog,
  Option,
  Select,
  Stack,
  Textarea,
  Typography,
} from "@mui/joy";
import { FormGroup, TextField as MuiTextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import toastr from "toastr";

import {
  createFlight,
  deleteFlight,
  updateFlight,
} from "../../../handlers/flight";
import { getGliders } from "../../../handlers/glider";
import { getLocations } from "../../../handlers/location";
import ConfirmDeleteModal from "../../modals/ConfirmDeleteModal";
import { GliderModal } from "../Gliders";
import { LocationModal } from "../Locations";

const FlightModal = ({ flight, open, handleClose }) => {
  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const [gliders, setGliders] = useState([]);
  const [glidersLoading, setGlidersLoading] = useState(true);
  const [gliderEditing, setGliderEditing] = useState(null);
  const [gliderModalOpen, setGliderModalOpen] = useState(false);
  const loadGliders = useCallback(async () => {
    setGlidersLoading(true);
    await getGliders()
      .then((response) => {
        if (response.success) {
          setGliders(response.gliders);
        } else {
          toastr.error(response.error);
        }
      })
      .catch((error) => toastr.error(error));
    setGlidersLoading(false);
  }, []);

  const [locations, setLocations] = useState([]);
  const [locationsLoading, setLocationsLoading] = useState(true);
  const [locationEditing, setLocationEditing] = useState(null);
  const [locationModalOpen, setLocationModalOpen] = useState(false);
  const loadLocations = useCallback(async () => {
    setLocationsLoading(true);
    await getLocations()
      .then((response) => {
        if (response.success) {
          setLocations(response.locations);
        } else {
          toastr.error(response.error);
        }
      })
      .catch((error) => toastr.error(error));
    setLocationsLoading(false);
  }, []);

  const [date, setDate] = useState(dayjs());
  const [duration, setDuration] = useState(dayjs("03:00", "HH:mm"));
  const [comment, setComment] = useState(flight?.comment || "");
  const addEmoji = (emoji) => () =>
    setComment((comment) => `${comment}${emoji}`);

  const handleSave = useCallback(
    async (e) => {
      e.preventDefault();

      if (!date) {
        toastr.error("Datum muss angegeben werden");
        return;
      } else if (!duration) {
        toastr.error("Dauer muss angegeben werden");
        return;
      }

      const formData = new FormData(e.currentTarget);
      const data = Object.fromEntries(formData);

      data.date = date.unix() * 1000;
      data.duration = duration.hour() * 60 + duration.minute();

      if (!data.startLocationId) {
        toastr.error("Startplatz muss angegeben werden");
        return;
      } else if (!data.endLocationId) {
        toastr.error("Zielplatz muss angegeben werden");
        return;
      } else if (!data.gliderId) {
        toastr.error("Gleiter muss angegeben werden");
        return;
      }

      const promise = flight?.id
        ? updateFlight(flight.id, data)
        : createFlight(data);

      setSaving(true);
      await promise
        .then((response) => {
          if (response.success) {
            handleClose();
          } else {
            toastr.error(response.error);
          }
        })
        .catch((error) => toastr.error(error));
      setSaving(false);
    },
    [duration, date, flight?.id, handleClose],
  );

  const handleDelete = useCallback(async () => {
    if (!flight?.id) return;

    setDeleting(true);
    await deleteFlight(flight.id)
      .then((response) => {
        if (response.success) {
          handleClose();
        } else {
          toastr.error(response.error);
        }
      })
      .catch((error) => toastr.error(error));
    setDeleting(false);
  }, [handleClose, flight?.id]);

  useEffect(() => {
    if (open) {
      if (flight?.date) {
        setDate(dayjs(flight.date));
      } else {
        setDate(dayjs());
      }
      if (flight?.duration) {
        setDuration(
          dayjs(
            `${Math.floor(flight.duration / 60)}:${flight.duration % 60}`,
            "HH:mm",
          ),
        );
      } else {
        setDuration(dayjs("03:00", "HH:mm"));
      }
      setComment(flight?.comment || "");

      loadGliders();
      loadLocations();
    }
  }, [flight, open, loadGliders, loadLocations]);

  return (
    <>
      <Modal open={open} onClose={() => handleClose()}>
        <ModalDialog
          sx={{
            width: {
              xs: "100%",
              sm: "80%",
              md: "60%",
              lg: "40%",
            },
            borderRadius: "md",
            p: 3,
            boxShadow: "lg",
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Typography
              component="h2"
              level="inherit"
              fontSize="1.25em"
              mb="0.25em"
            >
              {flight?.id ? "Flug bearbeiten" : "Neuer Flug"}
            </Typography>
            <IconButton
              color="danger"
              onClick={() => handleClose()}
              variant="soft"
              sx={{
                position: "absolute",
                top: "1.25rem",
                right: "1.5rem",
                p: 1,
              }}
            >
              <CloseIcon />
            </IconButton>
            <form onSubmit={(e) => handleSave(e)}>
              <Stack spacing={2}>
                <FormControl required>
                  <FormLabel>Startplatz</FormLabel>
                  <Box className="flex gap-3">
                    <Select
                      className="flex-grow"
                      placeholder="Bitte auswählen..."
                      defaultValue={flight?.startLocationId}
                      variant="outlined"
                      name="startLocationId"
                      required
                    >
                      {!locationsLoading &&
                        locations
                          .filter((l) => l?.type?.includes("start"))
                          .map((location, index) => {
                            return (
                              <Option key={index} value={location.id}>
                                {location.name}
                              </Option>
                            );
                          })}
                    </Select>

                    <IconButton
                      color="success"
                      variant="soft"
                      onClick={() => {
                        setLocationEditing({ type: "start" });
                        setLocationModalOpen(true);
                      }}
                    >
                      <AddIcon />
                    </IconButton>
                  </Box>
                </FormControl>
                <FormControl required>
                  <FormLabel>Landeplatz</FormLabel>
                  <Box className="flex gap-3">
                    <Select
                      className="flex-grow"
                      placeholder="Bitte auswählen..."
                      defaultValue={flight?.endLocationId}
                      variant="outlined"
                      name="endLocationId"
                      required
                    >
                      {!locationsLoading &&
                        locations
                          .filter((l) => l?.type?.includes("end"))
                          .map((location, index) => {
                            return (
                              <Option key={index} value={location.id}>
                                {location.name}
                              </Option>
                            );
                          })}
                    </Select>
                    <IconButton
                      color="success"
                      variant="soft"
                      onClick={() => {
                        setLocationEditing({ type: "end" });
                        setLocationModalOpen(true);
                      }}
                    >
                      <AddIcon />
                    </IconButton>
                  </Box>
                </FormControl>
                <FormControl required>
                  <FormLabel>Gleiter</FormLabel>
                  <Box className="flex gap-3">
                    <Select
                      className="flex-grow"
                      placeholder="Bitte auswählen..."
                      defaultValue={flight?.gliderId}
                      variant="outlined"
                      name="gliderId"
                      required
                    >
                      {!glidersLoading &&
                        gliders
                          .sort((glider) => (glider.liked ? -1 : 1))
                          .map((glider, index) => {
                            return (
                              <Option
                                key={index}
                                value={glider.id}
                                sx={{ color: glider?.color || "unset" }}
                              >
                                {glider.model}
                              </Option>
                            );
                          })}
                    </Select>
                    <IconButton
                      color="success"
                      variant="soft"
                      onClick={() => {
                        setGliderEditing();
                        setGliderModalOpen(true);
                      }}
                    >
                      <AddIcon />
                    </IconButton>
                  </Box>
                </FormControl>
                <Box
                  className="flex flex-col gap-3 items-end"
                  sx={{
                    flexDirection: { xs: "column", sm: "row" },
                  }}
                >
                  <FormGroup className="flex-grow w-full">
                    <FormLabel>Notiz</FormLabel>
                    <Textarea
                      placeholder="Deine Notiz"
                      value={comment}
                      name="comment"
                      onChange={(event) => setComment(event.target.value)}
                      minRows={2}
                      maxRows={4}
                      startDecorator={
                        <Box
                          sx={{
                            display: "flex",
                            gap: 0.5,
                          }}
                        >
                          {["👍", "😎", "😍", "🔥", "👌", "💪", "💨", "🌧️"].map(
                            (emoji, index) => (
                              <IconButton
                                key={index}
                                variant="outlined"
                                color="neutral"
                                onClick={addEmoji(emoji)}
                              >
                                {emoji}
                              </IconButton>
                            ),
                          )}
                        </Box>
                      }
                      endDecorator={
                        <Typography level="body3" sx={{ ml: "auto" }}>
                          {comment.length} Buchstabe(n)
                        </Typography>
                      }
                    />
                  </FormGroup>
                  <Box
                    className="flex w-full"
                    sx={{
                      gap: 2.1,
                      flexDirection: { sm: "column" },
                    }}
                  >
                    <DesktopDatePicker
                      label="Datum"
                      className="flex-grow"
                      name="date"
                      required
                      value={date}
                      onChange={(date) => setDate(date)}
                      inputFormat="DD.MM.YYYY"
                      renderInput={(params) => (
                        <MuiTextField required {...params} />
                      )}
                    />
                    <TimePicker
                      ampm={false}
                      name="duration"
                      className="flex-grow"
                      views={["hours", "minutes"]}
                      inputFormat="hh:mm"
                      mask="__:__"
                      label="Flugdauer"
                      value={duration}
                      onChange={(newValue) => setDuration(newValue)}
                      renderInput={(params) => (
                        <MuiTextField required {...params} />
                      )}
                    />
                  </Box>
                </Box>
                <Box className="flex gap-3 flex-wrap">
                  {flight?.id && (
                    <ConfirmDeleteModal
                      onDelete={function (callback) {
                        handleDelete().then(() => callback());
                      }}
                      loading={deleting}
                      text="Achtung, hierbei wird der Flug entgültig gelöscht."
                    />
                  )}
                  <Button
                    type="submit"
                    color="success"
                    variant="soft"
                    loading={saving}
                    className="flex-grow"
                  >
                    {flight?.id ? "Speichern" : "Erstellen"}
                  </Button>
                </Box>
              </Stack>
            </form>
          </LocalizationProvider>
        </ModalDialog>
      </Modal>
      <LocationModal
        open={locationModalOpen}
        location={locationEditing}
        handleClose={() => {
          setLocationModalOpen(false);
          loadLocations();
        }}
      />
      <GliderModal
        open={gliderModalOpen}
        glider={gliderEditing}
        handleClose={() => {
          setGliderModalOpen(false);
          loadGliders();
        }}
      />
    </>
  );
};

export default FlightModal;
