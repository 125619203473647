import { IconButton } from "@mui/joy";

const ActionButton = ({ label, position, icon, color, ...props }) => {
  return (
    <IconButton
      aria-label={label}
      size="md"
      variant="solid"
      color={color}
      sx={{
        position: "absolute",
        zIndex: 2,
        borderRadius: "50%",
        ...position,
      }}
      {...props}
    >
      {icon}
    </IconButton>
  );
};

export default ActionButton;
