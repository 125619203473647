import { Edit as EditIcon, SsidChart as ChartIcon } from "@mui/icons-material";
import { Box, IconButton } from "@mui/joy";
import { Skeleton, Table, TableBody, TableHead, TableRow } from "@mui/material";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import ConditionalTableCell from "../../helpers/ConditionalTableCell";

const FlightsTable = ({
  showSkeletons,
  flights,
  setFlightEditing,
  handleModalOpen,
  readOnly,
}) => {
  console.log({ readOnly });
  const navigate = useNavigate();

  return (
    <Table>
      <TableHead>
        <TableRow>
          <ConditionalTableCell showOnMobile>Datum</ConditionalTableCell>
          <ConditionalTableCell showOnMobile>Dauer</ConditionalTableCell>
          <ConditionalTableCell showOnTablet>Gleiter</ConditionalTableCell>
          <ConditionalTableCell showOnMobile>Startplatz</ConditionalTableCell>
          <ConditionalTableCell showOnDesktop>Landeplatz</ConditionalTableCell>
          <ConditionalTableCell showOnDesktop>Distanz</ConditionalTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {showSkeletons
          ? new Array(6).fill(0).map((_, index) => (
              <TableRow key={index}>
                <ConditionalTableCell showOnMobile>
                  <Skeleton />
                </ConditionalTableCell>
                <ConditionalTableCell showOnMobile>
                  <Skeleton />
                </ConditionalTableCell>
                <ConditionalTableCell showOnTablet>
                  <Skeleton />
                </ConditionalTableCell>
                <ConditionalTableCell showOnMobile>
                  <Skeleton />
                </ConditionalTableCell>
                <ConditionalTableCell showOnDesktop>
                  <Skeleton />
                </ConditionalTableCell>
                <ConditionalTableCell showOnDesktop>
                  <Skeleton />
                </ConditionalTableCell>
              </TableRow>
            ))
          : flights?.map((flight, index) => (
              <TableRow
                key={index}
                sx={{
                  ":hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.05)",
                  },
                }}
              >
                <ConditionalTableCell showOnMobile>
                  <Box className="flex items-center justify-between gap-2">
                    {flight?.date
                      ? moment(flight.date).format("DD.MM.YYYY")
                      : "-"}
                    <Box className="flex items-center gap-2">
                      {!readOnly && flight?.id && (
                        <IconButton
                          variant="soft"
                          color="info"
                          onClick={() => {
                            setFlightEditing(flight);
                            handleModalOpen(true);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      )}
                      {!readOnly && flight?.hasCustomData && (
                        <IconButton
                          variant="soft"
                          color="success"
                          onClick={() => {
                            navigate(`/flights/${flight.id}`);
                          }}
                        >
                          <ChartIcon />
                        </IconButton>
                      )}
                    </Box>
                  </Box>
                </ConditionalTableCell>
                <ConditionalTableCell showOnMobile>
                  {flight?.duration
                    ? moment
                        .utc(
                          moment
                            .duration(flight.duration, "minutes")
                            .as("milliseconds"),
                        )
                        .format("HH:mm")
                    : "-"}
                </ConditionalTableCell>
                <ConditionalTableCell showOnTablet>
                  {flight?.glider?.model ||
                    flight?.gliderModel ||
                    "Nicht angegeben"}
                </ConditionalTableCell>
                <ConditionalTableCell showOnMobile>
                  {flight?.startLocation?.name ||
                    flight?.startLocationName ||
                    "Nicht angegeben"}
                </ConditionalTableCell>
                <ConditionalTableCell showOnDesktop>
                  {flight?.endLocation?.name ||
                    flight?.endLocationName ||
                    "Nicht angegeben"}
                </ConditionalTableCell>
                <ConditionalTableCell showOnDesktop>
                  {flight?.distance
                    ? Math.round(parseFloat(flight.distance) / 100) / 10 + " km"
                    : "-"}
                </ConditionalTableCell>
              </TableRow>
            ))}
      </TableBody>
    </Table>
  );
};

export default FlightsTable;
