import { Add as AddIcon } from "@mui/icons-material";
import { Box, Button, Card, Typography } from "@mui/joy";
import { useCallback, useEffect, useState } from "react";
import toastr from "toastr";

import { getFlights } from "../../../handlers/flight";
import ExportItemsMenu from "../../modals/ExportItemsMenu";
import FlightModal from "./Modal";
import FlightsTable from "./Table";

const Flights = () => {
  const [flights, setFlights] = useState([]);
  const [flightsLoading, setFlightsLoading] = useState(true);
  const [flightEditing, setFlightEditing] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const loadFlights = useCallback(async () => {
    setFlightsLoading(true);
    await getFlights()
      .then((response) => {
        if (response.success) {
          setFlights(response.flights);
        } else {
          toastr.error(response.error);
        }
        setFlightsLoading(false);
      })
      .catch((error) => toastr.error(error));
  }, []);

  const handleClose = useCallback(() => {
    setFlightEditing(null);
    setModalOpen(false);
    loadFlights();
  }, [loadFlights]);

  useEffect(() => {
    loadFlights();
  }, [loadFlights]);

  return (
    <>
      <Box
        className="w-full flex flex-grow flex-col overflow-y-auto"
        sx={{
          padding: { xs: 2, md: 3 },
          backgroundColor: "background.default",
        }}
      >
        <Box className="flex justify-between items-center">
          <Card
            sx={{
              p: 3,
              gap: { xs: 2, sm: 3, md: 5 },
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Typography
              className="text-start"
              level="h4"
              sx={{ fontSize: "xl", fontWeight: "md" }}
            >
              Flüge
            </Typography>
            <Button
              variant="soft"
              endDecorator={<AddIcon />}
              color="success"
              onClick={() => {
                setFlightEditing(null);
                setModalOpen(true);
              }}
            >
              Erstellen
            </Button>
          </Card>
          <ExportItemsMenu itemType={"flights"} hasItems={flights.length > 0} />
        </Box>
        <Card sx={{ my: "1rem" }} className="flex flex-wrap gap-4">
          {flightsLoading ? (
            <FlightsTable showSkeletons />
          ) : (
            <FlightsTable
              flights={flights}
              setFlightsLoading={setFlightsLoading}
              setFlightEditing={setFlightEditing}
              handleModalOpen={setModalOpen}
            />
          )}
        </Card>
      </Box>
      <FlightModal
        flight={flightEditing}
        open={modalOpen}
        handleClose={handleClose}
      />
    </>
  );
};

export default Flights;
