import { createContext, useState, useCallback, useEffect } from "react";

import { getUser, logoutUser } from "../handlers/user";

export const UserContext = createContext();

const User = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({});

  const loadUser = useCallback(async () => {
    setLoading(true);
    return getUser()
      .then((response) => {
        if (response.success) {
          setUser({ ...response.user, loggedIn: true });
        } else {
          setUser({ loggedIn: false });
        }
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [setUser]);

  const logout = useCallback(async () => {
    await logoutUser().catch(() => {});
    setUser({ loggedIn: false });
    loadUser();
  }, [loadUser]);

  useEffect(() => {
    loadUser();
  }, [loadUser]);

  return (
    <UserContext.Provider value={{ loading, user, loadUser, logout }}>
      {children}
    </UserContext.Provider>
  );
};

export default User;
