import { Box, FormLabel, Radio, RadioGroup, Sheet } from "@mui/joy";

const RadioButtonGroup = ({ title, options, ...props }) => {
  return (
    <Box>
      <FormLabel
        sx={{
          mb: 0.5,
          fontWeight: "xl",
          fontSize: "xs",
          letterSpacing: "0.15rem",
        }}
      >
        {title}
      </FormLabel>
      <RadioGroup
        size="lg"
        sx={{ gap: 1.5, justifyContent: "space-between" }}
        row
        {...props}
      >
        {options.map((option, index) => (
          <Sheet
            key={index}
            sx={{
              p: 1.3,
              borderRadius: "md",
              boxShadow: "sm",
              bgcolor: "background.body",
              flexGrow: 1,
            }}
          >
            <Radio
              label={option.name}
              overlay
              disableIcon
              value={option.value}
              slotProps={{
                label: ({ checked }) => ({
                  sx: {
                    fontWeight: "lg",
                    fontSize: "md",
                    color: checked ? "text.primary" : "text.secondary",
                  },
                }),
                action: ({ checked }) => ({
                  sx: (theme) => ({
                    ...(checked && {
                      "--variant-borderWidth": "1px",
                      "&&": {
                        // && to increase the specificity to win the base :hover styles
                        borderColor: theme.vars.palette.primary[500],
                      },
                    }),
                  }),
                }),
              }}
            />
          </Sheet>
        ))}
      </RadioGroup>
    </Box>
  );
};

export default RadioButtonGroup;
