import { Add as AddIcon, Close as CloseIcon } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  FormLabel,
  IconButton,
  Modal,
  ModalDialog,
  Stack,
  Textarea,
  TextField,
  Typography,
} from "@mui/joy";
import {
  FormGroup,
  Skeleton,
  Table,
  TableBody,
  TableHead,
  TableRow,
} from "@mui/material";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { useCallback, useContext, useEffect, useState } from "react";
import toastr from "toastr";

import ConfirmDeleteModal from "../modals/ConfirmDeleteModal";
import ConditionalTableCell from "../helpers/ConditionalTableCell";
import RadioButtonGroup from "../helpers/RadioButtonGroup";
import { UserContext } from "../../contexts/User";

import {
  createLocation,
  deleteLocation,
  getLocations,
  updateLocation,
} from "../../handlers/location";

import googleMapsConfig from "../../config/googleMaps.json";
import { getAltitude } from "../../handlers/googleMaps";

const { googleAPIKey } = googleMapsConfig;

const defaultPosition = {
  lat: 47.3580211053668,
  lng: 12.303659546078336,
  alt: 1689,
};

export const LocationModal = ({
  location,
  open,
  handleClose,
  loadLocations,
}) => {
  const { user } = useContext(UserContext);

  const [markerPosition, setMarkerPosition] = useState({});
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [altitude, setAltitude] = useState("");
  const [comment, setComment] = useState("");
  const addEmoji = (emoji) => () =>
    setComment((comment) => `${comment}${emoji}`);

  useEffect(() => {
    setMarkerPosition({
      lat: location?.latitude || defaultPosition.lat,
      lng: location?.longitude || defaultPosition.lng,
      alt: location?.altitude || defaultPosition.alt,
    });
    setLatitude(location?.latitude || defaultPosition.lat);
    setLongitude(location?.longitude || defaultPosition.lng);
    setAltitude(location?.altitude || defaultPosition.alt);
    setComment(location?.comment || "");
  }, [location, open]);

  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const handleSave = useCallback(
    async (e) => {
      e.preventDefault();

      const formData = new FormData(e.currentTarget);
      const data = Object.fromEntries(formData);

      const regexForCoordinates = /^-?\d{1,3}\.\d*$/;
      if (
        !data.latitude ||
        !data.longitude ||
        !data.altitude ||
        !regexForCoordinates.test(parseFloat(data.latitude) + "") ||
        !regexForCoordinates.test(parseFloat(data.longitude) + "") ||
        !/^\d+$/.test(parseFloat(data.altitude) + "")
      ) {
        toastr.warning("Bitte geben Sie gültige Koordinaten ein.");
        return;
      }

      const promise = location?.id
        ? updateLocation(location.id, data)
        : createLocation(data);

      setSaving(true);
      await promise
        .then((response) => {
          if (response.success) {
            handleClose();
            loadLocations();
          } else {
            toastr.error(response.error);
          }
        })
        .catch((error) => toastr.error(error));
      setSaving(false);
    },
    [handleClose, loadLocations, location?.id],
  );
  const handleDelete = useCallback(async () => {
    if (!location?.id) return;

    setDeleting(true);
    await deleteLocation(location.id)
      .then((response) => {
        if (response.success) {
          handleClose();
          loadLocations();
        } else {
          toastr.error(response.error);
        }
      })
      .catch((error) => toastr.error(error));
    setDeleting(false);
  }, [handleClose, loadLocations, location?.id]);

  const handleMapClick = useCallback(
    ({ lat, lng }) => {
      if (!user.permissions?.canUseGoogleMaps) {
        toastr.warning(
          "Sie haben keine Berechtigung, Google Maps zu verwenden.",
        );
        return;
      }

      setMarkerPosition({ lat, lng });

      setLatitude(lat);
      setLongitude(lng);

      getAltitude({ lat, lng }).then((response) => {
        if (response.success) {
          setMarkerPosition((marker) => {
            const { lat: currentLat, lng: currentLng } = marker;

            if (lat === currentLat && lng === currentLng) {
              setAltitude(response.altitude);
            }
            return marker;
          });
        }
      });
    },
    [user.permissions?.canUseGoogleMaps],
  );

  return (
    <Modal
      open={open}
      onClose={() => handleClose()}
      sx={{ overflowY: "scroll" }}
    >
      <ModalDialog
        sx={{
          width: {
            xs: "100%",
            sm: "80%",
            md: "60%",
            lg: "50%",
          },
          borderRadius: "md",
          p: 3,
          boxShadow: "lg",
          maxHeight: "100vh",
          overflowY: "scroll",
        }}
      >
        <Typography
          component="h2"
          level="inherit"
          fontSize="1.25em"
          mb="0.25em"
        >
          {location?.name || "Start- und Landeplatz erstellen"}
        </Typography>
        <IconButton
          color="danger"
          onClick={() => handleClose()}
          variant="soft"
          sx={{
            position: "absolute",
            top: "1.25rem",
            right: "1.5rem",
            p: 1,
          }}
        >
          <CloseIcon />
        </IconButton>
        <form onSubmit={(e) => handleSave(e)}>
          <Stack spacing={2}>
            <TextField
              name="name"
              label="Name"
              defaultValue={location?.name}
              required
            />
            <RadioButtonGroup
              title="Typ"
              options={[
                { name: "Startplatz", value: "start" },
                { name: "Landeplatz", value: "end" },
                { name: "Beides", value: "start+end" },
              ]}
              name="type"
              required
              defaultValue={location?.type || "start"}
            />
            <Box
              className="flex gap-2"
              sx={{
                flexDirection: { xs: "column", md: "row" },
              }}
            >
              <Box className="flex-1">
                <LoadScript googleMapsApiKey={googleAPIKey}>
                  <GoogleMap
                    mapContainerStyle={{
                      flexGrow: 1,
                      minWidth: "300px",
                      aspectRatio: "16/9",
                    }}
                    center={defaultPosition}
                    zoom={10}
                    onClick={(e) => handleMapClick(e.latLng.toJSON())}
                  >
                    <Marker position={markerPosition} />
                  </GoogleMap>
                </LoadScript>
              </Box>
              <Box
                className="flex gap-2"
                sx={{
                  flexDirection: { xs: "row", md: "column" },
                  justifyContent: "space-between",
                  [`& .JoyTextField-root`]: {
                    width: { xs: "30%", md: "100%" },
                  },
                }}
              >
                <TextField
                  name="longitude"
                  label="Längengrad"
                  value={longitude}
                  onChange={(e) => {
                    setLongitude(e.target.value);
                    setMarkerPosition((pos) => ({
                      ...pos,
                      lng: parseFloat(e.target.value),
                    }));
                  }}
                  required
                />
                <TextField
                  name="latitude"
                  label="Breitengrad"
                  value={latitude}
                  onChange={(e) => {
                    setMarkerPosition((pos) => ({
                      ...pos,
                      lat: parseFloat(e.target.value || 0),
                    }));
                    setLatitude(e.target.value);
                  }}
                  required
                />
                <TextField
                  name="altitude"
                  label="Höhe"
                  value={altitude}
                  onChange={(e) => {
                    setAltitude(e.target.value);
                    setMarkerPosition((pos) => ({
                      ...pos,
                      alt: parseFloat(e.target.value),
                    }));
                  }}
                  required
                />
              </Box>
            </Box>
            <FormGroup>
              <FormLabel>Notiz</FormLabel>
              <Textarea
                placeholder="Deine Notiz"
                value={comment}
                name="comment"
                onChange={(event) => setComment(event.target.value)}
                minRows={2}
                maxRows={4}
                startDecorator={
                  <Box
                    sx={{
                      display: "flex",
                      gap: 0.5,
                    }}
                  >
                    {["👍", "😎", "😍", "🔥", "👌", "💪", "💨", "🌧️"].map(
                      (emoji, index) => (
                        <IconButton
                          key={index}
                          variant="outlined"
                          color="neutral"
                          onClick={addEmoji(emoji)}
                        >
                          {emoji}
                        </IconButton>
                      ),
                    )}
                  </Box>
                }
                endDecorator={
                  <Typography level="body3" sx={{ ml: "auto" }}>
                    {comment.length} Buchstabe(n)
                  </Typography>
                }
              />
            </FormGroup>
            <Box className="flex gap-3 flex-wrap">
              {location?.id && (
                <ConfirmDeleteModal
                  onDelete={function (callback) {
                    handleDelete().then(() => callback());
                  }}
                  loading={deleting}
                  text="Achtung, hierbei wird der Standort entgültig gelöscht."
                />
              )}
              <Button
                type="submit"
                color="success"
                variant="soft"
                loading={saving}
                className="flex-grow"
              >
                {location?.id ? "Speichern" : "Erstellen"}
              </Button>
            </Box>
          </Stack>
        </form>
      </ModalDialog>
    </Modal>
  );
};

const LocationsTable = ({
  showSkeletons,
  locations,
  setLocationEditing,
  handleModalOpen,
}) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <ConditionalTableCell showOnMobile>Typ</ConditionalTableCell>
          <ConditionalTableCell showOnMobile>Name</ConditionalTableCell>
          <ConditionalTableCell showOnTablet>Höhenmeter</ConditionalTableCell>
          <ConditionalTableCell showOnDesktop>Notiz</ConditionalTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {showSkeletons
          ? new Array(6).fill(0).map((_, index) => (
              <TableRow key={index}>
                <ConditionalTableCell showOnMobile>
                  <Skeleton />
                </ConditionalTableCell>
                <ConditionalTableCell showOnMobile>
                  <Skeleton />
                </ConditionalTableCell>
                <ConditionalTableCell showOnTablet>
                  <Skeleton />
                </ConditionalTableCell>
                <ConditionalTableCell showOnDesktop>
                  <Skeleton />
                </ConditionalTableCell>
              </TableRow>
            ))
          : locations.map((location, index) => (
              <TableRow
                key={index}
                sx={{
                  cursor: "pointer",
                  ":hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.05)",
                  },
                }}
                onClick={() => {
                  setLocationEditing(location);
                  handleModalOpen(true);
                }}
              >
                <ConditionalTableCell showOnMobile>
                  {location?.type === "start"
                    ? "Startplatz"
                    : location?.type === "end"
                    ? "Landeplatz"
                    : "Start- und Landelpatz"}
                </ConditionalTableCell>
                <ConditionalTableCell showOnMobile>
                  {location?.name}
                </ConditionalTableCell>
                <ConditionalTableCell showOnTablet>
                  {location?.altitude}
                </ConditionalTableCell>
                <ConditionalTableCell showOnDesktop>
                  {location?.comment?.length > 30
                    ? location?.comment?.substring(0, 30) + "..."
                    : location?.comment}
                </ConditionalTableCell>
              </TableRow>
            ))}
      </TableBody>
    </Table>
  );
};

const Locations = () => {
  const [locations, setLocations] = useState([]);
  const [locationsLoading, setLocationsLoading] = useState(true);

  const [locationEditing, setLocationEditing] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const loadLocations = useCallback(async () => {
    setLocationsLoading(true);
    await getLocations()
      .then((response) => {
        if (response.success) {
          setLocations(response.locations);
        } else {
          toastr.error(response.error);
        }
        setLocationsLoading(false);
      })
      .catch((error) => toastr.error(error));
  }, []);

  const handleClose = useCallback(() => {
    setLocationEditing(null);
    setModalOpen(false);
  }, []);

  useEffect(() => {
    loadLocations();
  }, [loadLocations]);

  return (
    <>
      <Box
        className="w-full flex flex-grow flex-col overflow-y-auto"
        sx={{
          padding: { xs: 2, md: 3 },
          backgroundColor: "background.default",
        }}
      >
        <Card
          sx={{
            mr: "auto",
            ml: { xs: "auto", md: 0 },
            p: 3,
            alignItems: "center",
            gap: { xs: 3, md: 5 },
            flexDirection: "row",
          }}
        >
          <Typography
            className="text-start"
            level="h4"
            sx={{ fontSize: "xl", fontWeight: "md" }}
          >
            Start- und Landespots
          </Typography>
          <Button
            variant="soft"
            endDecorator={<AddIcon />}
            color="success"
            onClick={() => {
              setLocationEditing(null);
              setModalOpen(true);
            }}
          >
            Erstellen
          </Button>
        </Card>
        <Card sx={{ my: "1.5rem" }} className="flex flex-wrap gap-4">
          {locationsLoading ? (
            <LocationsTable showSkeletons />
          ) : (
            <LocationsTable
              locations={locations}
              setLocationEditing={setLocationEditing}
              handleModalOpen={setModalOpen}
            />
          )}
        </Card>
      </Box>
      <LocationModal
        location={locationEditing}
        open={modalOpen}
        handleClose={handleClose}
        loadLocations={loadLocations}
      />
    </>
  );
};

export default Locations;
