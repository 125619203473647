import APIHandler from "./apiHandler";

export async function getUser() {
  return APIHandler.get({ path: "/user" });
}
export async function updateUser(body) {
  return APIHandler.put({ path: "/user", data: body });
}

// Auth
export async function registerUser(user) {
  return APIHandler.post({ path: "/auth/register", data: user });
}
export async function logoutUser() {
  return APIHandler.post({ path: "/auth/logout" });
}
export async function loginUser(user) {
  return APIHandler.post({ path: "/auth/login", data: user });
}
