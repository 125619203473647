import {
  Add as AddIcon,
  Close as CloseIcon,
  Edit as EditIcon,
  Favorite as FavoriteIcon,
  FavoriteBorder as FavoriteBorderIcon,
  People as PeopleIcon,
  Person as PersonIcon,
} from "@mui/icons-material";
import {
  AspectRatio,
  Autocomplete,
  Box,
  Button,
  Card,
  CardOverflow,
  Divider,
  FormControl,
  FormLabel,
  IconButton,
  Modal,
  ModalDialog,
  Option,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/joy";
import { Skeleton } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import toastr from "toastr";

import GliderIcon from "../../icons/GliderIcon";
import ActionButton from "../helpers/ActionButton";
import ConfirmDeleteModal from "../modals/ConfirmDeleteModal";

import {
  createGlider,
  deleteGlider,
  getGliders,
  updateGlider,
} from "../../handlers/glider";

import gliderCategories from "../../assets/gliderCategories.json";
import gliderManufacturers from "../../assets/gliderManufacturers.json";
import gliderTypes from "../../assets/gliderTypes.json";

export const GliderModal = ({ glider, open, handleClose, loadGliders }) => {
  const [liked, setLiked] = useState(false);

  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const handleSave = useCallback(
    async (e) => {
      e.preventDefault();

      const formData = new FormData(e.currentTarget);
      const data = Object.fromEntries(formData);

      data.liked = liked;

      const promise = glider?.id
        ? updateGlider(glider.id, data)
        : createGlider(data);

      setSaving(true);
      await promise
        .then((response) => {
          if (response.success) {
            handleClose();
            loadGliders();
          } else {
            toastr.error(response.error);
          }
        })
        .catch((error) => toastr.error(error));
      setSaving(false);
    },
    [handleClose, loadGliders, liked, glider?.id],
  );
  const handleDelete = useCallback(async () => {
    if (!glider?.id) return;

    setDeleting(true);
    await deleteGlider(glider.id)
      .then((response) => {
        if (response.success) {
          handleClose();
          loadGliders();
        } else {
          toastr.error(response.error);
        }
      })
      .catch((error) => toastr.error(error));
    setDeleting(false);
  }, [handleClose, loadGliders, glider?.id]);

  useEffect(() => {
    setLiked(glider?.liked || false);
  }, [glider?.liked, glider?.id, open]);

  return (
    <Modal open={open} onClose={() => handleClose()}>
      <ModalDialog
        sx={{
          width: {
            xs: "100%",
            sm: "80%",
            md: "60%",
            lg: "40%",
          },
          borderRadius: "md",
          p: 3,
          boxShadow: "lg",
        }}
      >
        <Typography
          component="h2"
          level="inherit"
          fontSize="1.25em"
          mb="0.25em"
        >
          {glider?.model || "Gleitschirm erstellen"}
        </Typography>
        <IconButton
          color="danger"
          onClick={() => handleClose()}
          variant="soft"
          sx={{
            position: "absolute",
            top: "1.25rem",
            right: "1.5rem",
            p: 1,
          }}
        >
          <CloseIcon />
        </IconButton>
        <form onSubmit={(e) => handleSave(e)}>
          <Stack spacing={3}>
            <Box className="flex gap-5 items-end">
              <TextField
                className="flex-grow"
                label="Modell"
                autoFocus
                required
                name="model"
                defaultValue={glider?.model || ""}
              />
              <IconButton
                color="danger"
                onClick={() => setLiked(!liked)}
                variant="soft"
              >
                {liked ? <FavoriteIcon /> : <FavoriteBorderIcon />}
              </IconButton>
            </Box>
            <FormControl required>
              <FormLabel>Kategorie</FormLabel>
              <Select
                required
                name="category"
                defaultValue={glider?.category || gliderCategories[0]}
                placeholder="Kategorie auswählen"
              >
                {gliderCategories.map((category) => (
                  <Option key={category} value={category}>
                    {category}
                  </Option>
                ))}
              </Select>
            </FormControl>
            <Box className="flex gap-3">
              <FormControl className="flex-grow" required>
                <FormLabel>Art</FormLabel>
                <Select
                  label="Art"
                  required
                  name="type"
                  defaultValue={glider?.type || gliderTypes[0]}
                  placeholder="Art auswählen"
                >
                  {gliderTypes.map((type) => (
                    <Option key={type} value={type}>
                      {type}
                    </Option>
                  ))}
                </Select>
              </FormControl>
              <FormControl className="w-1/5">
                <FormLabel>Farbe</FormLabel>
                <input
                  type="color"
                  name="color"
                  defaultValue={glider?.color || "#000"}
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "0.5rem",
                    border: "1px solid #c1c1c1",
                    padding: "0.5rem",
                  }}
                />
              </FormControl>
            </Box>
            <FormControl required>
              <FormLabel>Hersteller</FormLabel>
              <Autocomplete
                name="manufacturer"
                defaultValue={glider?.manufacturer || ""}
                placeholder="Hersteller auswählen"
                options={gliderManufacturers}
                required
                freeSolo
                autoSelect
                openOnFocus
              />
            </FormControl>
            <Box className="flex gap-3 flex-wrap">
              {glider?.id && (
                <ConfirmDeleteModal
                  onDelete={function (callback) {
                    handleDelete().then(() => callback());
                  }}
                  loading={deleting}
                  text="Achtung, hierbei wird der Gleiter entgültig gelöscht."
                />
              )}
              <Button
                type="submit"
                color="success"
                variant="soft"
                loading={saving}
                className="flex-grow"
              >
                {glider?.id ? "Speichern" : "Erstellen"}
              </Button>
            </Box>
          </Stack>
        </form>
      </ModalDialog>
    </Modal>
  );
};

const GliderCard = ({
  glider,
  setGliderEditing,
  handleModalOpen,
  handleGliderChange,
}) => {
  const [likeLoading, setLikeLoading] = useState(false);
  const [liked, setLiked] = useState(glider?.liked || false);

  const handleLikedToggle = useCallback(async () => {
    if (likeLoading) return;

    const likeState = !liked;
    setLikeLoading(true);
    await updateGlider(glider.id, { liked: likeState }).then((response) => {
      if (response.success) {
        setLiked(likeState);
        handleGliderChange(glider.id, { liked: likeState });
      } else {
        toastr.error(response.error);
      }
      setLikeLoading(false);
    });
  }, [liked, likeLoading, glider, handleGliderChange]);

  useEffect(() => {
    setLiked(glider?.liked || false);
  }, [glider]);

  return (
    <Card
      variant="outlined"
      sx={{
        flexBasis: {
          xs: "100%",
          sm: "calc(50% - 1rem)",
          md: "calc(33% - 1rem)",
          lg: "calc(25% - 1rem)",
          xl: "calc(20% - 1rem)",
        },
      }}
    >
      <CardOverflow>
        <AspectRatio
          ratio={16 / 9}
          sx={{
            [`& .JoyAspectRatio-variantSoft`]: {
              backgroundColor: "#c5c5c5",
            },
          }}
        >
          <GliderIcon fill={glider.color} />
        </AspectRatio>
        <ActionButton
          label={glider.model + " bearbeiten"}
          position={{ left: "1rem", bottom: "1rem" }}
          icon={<EditIcon />}
          color="primary"
          onClick={() => {
            setGliderEditing(glider);
            handleModalOpen();
          }}
        />
        <ActionButton
          label={glider.model + " favorisieren"}
          position={{ right: "1rem", bottom: "1rem" }}
          icon={liked ? <FavoriteIcon /> : <FavoriteBorderIcon />}
          color="danger"
          onClick={() => handleLikedToggle()}
        />
      </CardOverflow>
      <Typography level="h5" sx={{ fontSize: "md", mt: 2 }}>
        {glider.model}
      </Typography>
      <Typography level="body2" sx={{ mt: 0.5, mb: 2 }}>
        {glider.category}
      </Typography>
      <Divider inset="context" />
      <CardOverflow
        variant="soft"
        sx={{
          display: "flex",
          gap: 1.5,
          py: 1.5,
          px: "var(--Card-padding)",
          bgcolor: "background.level1",
        }}
      >
        <Typography
          level="body3"
          sx={{ fontWeight: "md", color: "text.secondary" }}
        >
          {glider.manufacturer}
        </Typography>
        <Divider orientation="vertical" />
        <Typography level="body2" sx={{ color: "text.secondary" }}>
          {glider.type === "Solo" ? <PersonIcon /> : <PeopleIcon />}
        </Typography>
        <Box className="flex-grow flex justify-end">
          <Typography
            className="ml-auto"
            level="body2"
            sx={{ color: "text.secondary" }}
          >
            {glider.flights &&
              glider.flights + " " + (glider.flights === 1 ? "Flug" : "Flüge")}
          </Typography>
        </Box>
      </CardOverflow>
    </Card>
  );
};

const Gliders = () => {
  const [gliders, setGliders] = useState([]);
  const [glidersLoading, setGlidersLoading] = useState(true);

  const [gliderEditing, setGliderEditing] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const loadGliders = useCallback(async () => {
    setGlidersLoading(true);
    await getGliders()
      .then((response) => {
        if (response.success) {
          setGliders(response.gliders);
        } else {
          toastr.error(response.error);
        }
        setGlidersLoading(false);
      })
      .catch((error) => toastr.error(error));
  }, []);

  const handleClose = useCallback(() => {
    setGliderEditing(null);
    setModalOpen(false);
  }, []);

  useEffect(() => {
    loadGliders();
  }, [loadGliders]);

  return (
    <>
      <Box
        className="w-full flex flex-grow flex-col overflow-y-auto"
        sx={{
          padding: { xs: 2, md: 3 },
          backgroundColor: "background.default",
        }}
      >
        <Card
          sx={{
            p: 3,
            gap: { xs: 3, md: 5 },
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            mr: { xs: "0", sm: "auto" },
            ml: { xs: "0", sm: "auto", md: "0" },
          }}
        >
          <Typography
            className="text-start"
            level="h4"
            sx={{ fontSize: "xl", fontWeight: "md" }}
          >
            Gleiter
          </Typography>
          <Button
            variant="soft"
            endDecorator={<AddIcon />}
            color="success"
            onClick={() => {
              setGliderEditing(null);
              setModalOpen(true);
            }}
          >
            Erstellen
          </Button>
        </Card>
        <Box sx={{ my: "1.5rem" }} className="flex flex-wrap gap-4">
          {glidersLoading
            ? new Array(6).fill(0).map((_, index) => (
                <Skeleton
                  key={index}
                  variant="rectangular"
                  sx={{
                    flexBasis: {
                      xs: "100%",
                      sm: "calc(50% - 1rem)",
                      md: "calc(33% - 1rem)",
                      lg: "calc(25% - 1rem)",
                      xl: "calc(20% - 1rem)",
                    },
                  }}
                  height={315}
                  style={{ borderRadius: "0.5rem" }}
                  animation="wave"
                />
              ))
            : gliders
                .sort((glider) => (glider.liked ? -1 : 1))
                .map((glider, index) => (
                  <GliderCard
                    key={index}
                    glider={{ ...glider }}
                    handleModalOpen={() => setModalOpen(true)}
                    setGliderEditing={setGliderEditing}
                    handleGliderChange={(gliderId, data) => {
                      const newGliders = [...gliders];
                      const gliderIndex = newGliders.findIndex(
                        (glider) => glider.id === gliderId,
                      );
                      newGliders[gliderIndex] = {
                        ...newGliders[gliderIndex],
                        ...data,
                      };
                      setGliders(newGliders);
                    }}
                  />
                ))}
        </Box>
      </Box>
      <GliderModal
        glider={gliderEditing}
        open={modalOpen}
        loadGliders={loadGliders}
        handleClose={handleClose}
      />
    </>
  );
};

export default Gliders;
