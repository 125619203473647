import {
  Box,
  Button,
  Card,
  FormLabel,
  IconButton,
  Modal,
  ModalDialog,
  TextField,
  Typography,
} from "@mui/joy";
import { Add as AddIcon, Close as CloseIcon } from "@mui/icons-material";
import { useCallback, useContext, useState } from "react";
import toastr from "toastr";
import {
  FormGroup,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
} from "@mui/material";

import { UserContext } from "../../contexts/User";
import { updateUser } from "../../handlers/user";

export const NameModal = ({ open, handleClose, type }) => {
  const { user, loadUser } = useContext(UserContext);
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setLoading(true);
      return updateUser({ [type]: e.target[type].value })
        .then((response) => {
          if (response.success) {
            loadUser();
            handleClose();
          } else {
            toastr.error(response.error);
          }
          setLoading(false);
        })
        .catch((error) => toastr.error(error));
    },
    [type, handleClose, loadUser],
  );

  return (
    <Modal
      open={open}
      onClose={() => handleClose()}
      sx={{ overflowY: "scroll" }}
    >
      <ModalDialog
        sx={{
          width: {
            xs: "80%",
            sm: "50%",
            md: "40%",
            lg: "30%",
          },
          borderRadius: "md",
          py: 8,
          boxShadow: "lg",
        }}
      >
        <IconButton
          color="danger"
          onClick={() => handleClose()}
          variant="soft"
          sx={{
            position: "absolute",
            top: "1.25rem",
            right: "1.5rem",
            p: 1,
            mb: "1.5rem",
          }}
        >
          <CloseIcon />
        </IconButton>
        <form
          onSubmit={(e) => handleSubmit(e)}
          className={"flex flex-col items-center gap-3 py-8 my-6"}
        >
          <FormGroup className="w-1/2">
            <FormLabel
              sx={{
                fontSize: "1.25rem",
              }}
            >
              Neuer {type === "firstname" ? "Vorname" : "Nachname"}
            </FormLabel>
            <TextField
              placeholder={user?.[type]}
              name={type}
              className="w-full my-3 mb-0"
            />
          </FormGroup>
          <Button
            variant="soft"
            color="success"
            loading={loading}
            className="w-1/2"
            type="submit"
          >
            Speichern
          </Button>
        </form>
      </ModalDialog>
    </Modal>
  );
};

export const SettingsTable = () => {
  const { user } = useContext(UserContext);
  const [modalOpen, setModalOpen] = useState(false);
  const [etype, setEtype] = useState("");

  const handleClose = useCallback(() => {
    setModalOpen(false);
  }, []);

  return (
    <>
      <Table>
        <TableHead></TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Vorname</TableCell>
            <TableCell>{user?.firstname}</TableCell>
            <TableCell>
              <Button
                variant="soft"
                endDecorator={<AddIcon />}
                color="success"
                onClick={() => {
                  setModalOpen(true);
                  setEtype("firstname");
                }}
              >
                Bearbeiten
              </Button>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Nachname</TableCell>
            <TableCell>{user?.lastname}</TableCell>
            <TableCell>
              <Button
                variant="soft"
                endDecorator={<AddIcon />}
                color="success"
                onClick={() => {
                  setModalOpen(true);
                  setEtype("lastname");
                }}
              >
                Bearbeiten
              </Button>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Email</TableCell>
            <TableCell>{user?.email}</TableCell>
            <TableCell>
              <Button
                variant="soft"
                endDecorator={<AddIcon />}
                color="success"
                onClick={() => {}}
              >
                Bearbeiten
              </Button>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Passwort</TableCell>
            <TableCell>**********</TableCell>
            <TableCell>
              <Button
                variant="soft"
                endDecorator={<AddIcon />}
                color="success"
                onClick={() => {}}
              >
                Bearbeiten
              </Button>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <NameModal open={modalOpen} handleClose={handleClose} type={etype} />
    </>
  );
};

const Settings = () => {
  return (
    <Box
      className="w-full flex flex-grow flex-col overflow-y-auto"
      sx={{
        padding: { xs: 1, md: 3 },
        backgroundColor: "background.default",
      }}
    >
      <Card
        sx={{
          mr: "auto",
          ml: { xs: "auto", md: 0 },
          mt: { xs: 2, md: 0 },
          p: 3,
          gap: { xs: 2, md: 5 },
          flexDirection: "row",
        }}
      >
        <Typography level="h4" align="center" sx={{ marginBottom: "0rem" }}>
          Einstellungen
        </Typography>
      </Card>
      <Card sx={{ my: "1rem" }} className="flex flex-wrap gap-4">
        <SettingsTable />
      </Card>
    </Box>
  );
};

export default Settings;
