import APIHandler from "./apiHandler";

export async function getStudents() {
  return APIHandler.get({ path: "/teacher/students" });
}

export async function updateTask({ studentId, taskName, done }) {
  return APIHandler.post({
    path: "/teacher/updateTask",
    data: { studentId, taskName, done },
  });
}
