import {
  Add as AddIcon,
  Close as CloseIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Checkbox,
  IconButton,
  Modal,
  ModalDialog,
  Stack,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  TextField,
  Typography,
} from "@mui/joy";
import { Skeleton, Table, TableBody, TableHead, TableRow } from "@mui/material";
import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import toastr from "toastr";

import {
  createFlightSchool,
  deleteFlightSchool,
  getFlightSchools,
  updateFlightSchool,
} from "../../../handlers/flightSchool";
import ConditionalTableCell from "../../helpers/ConditionalTableCell";
import ConfirmDeleteModal from "../../modals/ConfirmDeleteModal";

const defaultPermissions = {
  student: {
    glidersLimit: 3,
    locationsLimit: 3,
    flightsLimit: 6,
    canUseGoogleMaps: false,
    canUseElevation: false,
  },
  teacher: {
    glidersLimit: 5,
    locationsLimit: 10,
    flightsLimit: 50,
    canUseGoogleMaps: true,
    canUseElevation: true,
  },
  school: {
    glidersLimit: 25,
    locationsLimit: 10,
    flightsLimit: 1000,
    canUseGoogleMaps: true,
    canUseElevation: true,
  },
};

const PermissionSet = ({ type, permissions, onChange }) => {
  return (
    <Box className="flex flex-col gap-3">
      <Box className="flex gap-3 flex-wrap">
        <TextField
          type="number"
          label="Flieger Limit"
          className="flex-grow"
          defaultValue={
            permissions?.glidersLimit || defaultPermissions[type].glidersLimit
          }
          onChange={(e) =>
            onChange(type, { glidersLimit: parseFloat(e.target.value) })
          }
        />
        <TextField
          type="number"
          label="Spots Limit"
          className="flex-grow"
          defaultValue={
            permissions?.locationsLimit ||
            defaultPermissions[type].locationsLimit
          }
          onChange={(e) =>
            onChange(type, { locationsLimit: parseFloat(e.target.value) })
          }
        />
        <TextField
          label="Flüge Limit"
          type="number"
          defaultValue={
            permissions?.flightsLimit || defaultPermissions[type].flightsLimit
          }
          onChange={(e) =>
            onChange(type, { flightsLimit: parseFloat(e.target.value) })
          }
        />
      </Box>
      <Box className="flex gap-3 flex-wrap">
        <Checkbox
          label="Google Maps"
          checked={permissions?.canUseGoogleMaps}
          onChange={(e) =>
            onChange(type, { canUseGoogleMaps: e.target.checked })
          }
        />
        <Checkbox
          label="Höhenangaben"
          checked={permissions?.canUseElevation}
          onChange={(e) =>
            onChange(type, { canUseElevation: e.target.checked })
          }
        />
      </Box>
    </Box>
  );
};

const FlightSchoolModal = ({
  flightSchool,
  open,
  handleClose,
  loadFlightSchools,
}) => {
  const [permissions, setPermissions] = useState(defaultPermissions);

  const handlePermissionChange = useCallback(
    (type, permission) => {
      setPermissions({
        ...permissions,
        [type]: { ...permissions[type], ...permission },
      });
    },
    [permissions],
  );

  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const handleSave = useCallback(
    async (e) => {
      e.preventDefault();

      const formData = new FormData(e.currentTarget);
      const data = Object.fromEntries(formData);

      const types = Object.keys(defaultPermissions);

      const permissionData = Object.assign(
        {},
        ...Object.keys(defaultPermissions).map((type) => ({ [type]: {} })),
      );
      for (const type of types) {
        const permissionTypes = Object.keys(defaultPermissions[type]);

        for (const permissionType of permissionTypes) {
          if (
            !permissions[type][permissionType] &&
            typeof permissions[type][permissionType] !== "boolean"
          ) {
            toastr.error(
              `Bitte alle Berechtigungen für ${type} angeben (${permissionType})`,
            );
            return;
          }
          permissionData[type][permissionType] =
            permissions[type][permissionType];
        }
      }

      data.permissions = permissionData;

      const promise = flightSchool?.id
        ? updateFlightSchool(flightSchool.id, data)
        : createFlightSchool(data);

      setSaving(true);
      await promise
        .then((response) => {
          if (response.success) {
            handleClose();
            loadFlightSchools();
          } else {
            toastr.error(response.error);
          }
        })
        .catch((error) => toastr.error(error));
      setSaving(false);
    },
    [flightSchool?.id, handleClose, loadFlightSchools, permissions],
  );

  const handleDelete = useCallback(async () => {
    if (!flightSchool?.id) return;

    setDeleting(true);
    await deleteFlightSchool(flightSchool.id)
      .then((response) => {
        if (response.success) {
          handleClose();
          loadFlightSchools();
        } else {
          toastr.error(response.error);
        }
      })
      .catch((error) => toastr.error(error));
    setDeleting(false);
  }, [handleClose, loadFlightSchools, flightSchool?.id]);

  useEffect(() => {
    setPermissions(flightSchool?.permissions || defaultPermissions);
  }, [flightSchool?.permissions, open]);

  return (
    <Modal
      open={open}
      onClose={() => handleClose()}
      sx={{ overflowY: "scroll" }}
    >
      <ModalDialog
        sx={{
          width: {
            xs: "100%",
            sm: "80%",
            md: "60%",
            lg: "50%",
          },
          borderRadius: "md",
          p: 3,
          boxShadow: "lg",
          maxHeight: "100vh",
          overflowY: "scroll",
        }}
      >
        <Typography
          component="h2"
          level="inherit"
          fontSize="1.25em"
          mb="0.25em"
        >
          {flightSchool?.id ? "Flugschule bearbeiten" : "Neue Flugschule"}
        </Typography>
        <IconButton
          color="danger"
          onClick={() => handleClose()}
          variant="soft"
          sx={{
            position: "absolute",
            top: "1.25rem",
            right: "1.5rem",
            p: 1,
          }}
        >
          <CloseIcon />
        </IconButton>
        <form onSubmit={(e) => handleSave(e)}>
          <Stack spacing={2}>
            <Stack spacing={2}>
              <TextField
                required
                name="name"
                label="Name"
                defaultValue={flightSchool?.name}
              />
              <TextField
                required
                name="street"
                label="Straße + Nummer"
                defaultValue={flightSchool?.street}
              />
              <Box className="flex flex-row gap-3 flex-wrap">
                <TextField
                  required
                  name="postalCode"
                  label="Postleitzahl"
                  defaultValue={flightSchool?.postalCode}
                />
                <TextField
                  required
                  name="city"
                  label="Stadt"
                  className="flex-grow"
                  defaultValue={flightSchool?.city}
                />
                <TextField
                  required
                  label="Land"
                  name="country"
                  defaultValue={flightSchool?.country}
                />
              </Box>
            </Stack>
            <Box>
              <Typography level="h6" align="center" sx={{ mb: 1 }}>
                Berechtigungen
              </Typography>
              <Tabs size="lg" defaultValue={0}>
                <TabList variant="soft" color="primary">
                  <Tab>Schüler</Tab>
                  <Tab>Lehrer</Tab>
                  <Tab>Schule</Tab>
                </TabList>
                {Object.keys(defaultPermissions).map((type, index) => (
                  <TabPanel value={index} key={index}>
                    <PermissionSet
                      type={type}
                      onChange={handlePermissionChange}
                      permissions={permissions?.[type]}
                    />
                  </TabPanel>
                ))}
              </Tabs>
            </Box>
            <Box className="flex gap-3 flex-wrap">
              <TextField
                label="Website URL"
                type="url"
                name="website"
                className="flex-grow"
                defaultValue={flightSchool?.website}
              />
            </Box>
            <Box className="flex gap-3 flex-wrap">
              {flightSchool?.id && (
                <ConfirmDeleteModal
                  onDelete={function (callback) {
                    handleDelete().then(() => callback());
                  }}
                  loading={deleting}
                  text="Achtung, hierbei wird die Flugschule entgültig gelöscht."
                />
              )}
              <Button
                type="submit"
                color="success"
                variant="soft"
                loading={saving}
                className="flex-grow"
              >
                {flightSchool?.id ? "Speichern" : "Erstellen"}
              </Button>
            </Box>
          </Stack>
        </form>
      </ModalDialog>
    </Modal>
  );
};

const FlightSchoolsTable = ({
  showSkeletons,
  flightSchools,
  setFlightSchoolEditing,
  handleModalOpen,
}) => {
  const navigate = useNavigate();

  return (
    <Table>
      <TableHead>
        <TableRow>
          <ConditionalTableCell showOnMobile>Name</ConditionalTableCell>
          <ConditionalTableCell showOnTablet>Stadt</ConditionalTableCell>
          <ConditionalTableCell showOnDesktop>Land</ConditionalTableCell>
          <ConditionalTableCell showOnMobile>
            Angemeldet seit
          </ConditionalTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {showSkeletons
          ? new Array(6).fill(0).map((_, index) => (
              <TableRow key={index}>
                <ConditionalTableCell showOnMobile>
                  <Skeleton />
                </ConditionalTableCell>
                <ConditionalTableCell showOnTablet>
                  <Skeleton />
                </ConditionalTableCell>
                <ConditionalTableCell showOnDesktop>
                  <Skeleton />
                </ConditionalTableCell>
                <ConditionalTableCell showOnMobile>
                  <Skeleton />
                </ConditionalTableCell>
              </TableRow>
            ))
          : flightSchools?.map((flightSchool, index) => (
              <TableRow
                key={index}
                sx={{
                  cursor: "pointer",
                  ":hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.05)",
                  },
                }}
                onClick={() => navigate(`/flightSchools/${flightSchool.id}`)}
              >
                <ConditionalTableCell showOnMobile>
                  <Box className="flex items-center gap-3">
                    <IconButton
                      color="primary"
                      variant="soft"
                      onClick={(e) => {
                        e.stopPropagation();
                        setFlightSchoolEditing(flightSchool);
                        handleModalOpen(true);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                    {flightSchool.name}
                  </Box>
                </ConditionalTableCell>
                <ConditionalTableCell showOnTablet>
                  {flightSchool.city}
                </ConditionalTableCell>
                <ConditionalTableCell showOnDesktop>
                  {flightSchool.country}
                </ConditionalTableCell>
                <ConditionalTableCell showOnMobile>
                  {dayjs(flightSchool.createdAt).format("DD.MM.YYYY")}
                </ConditionalTableCell>
              </TableRow>
            ))}
      </TableBody>
    </Table>
  );
};

const FlightSchools = () => {
  const [flightSchools, setFlightSchools] = useState([]);
  const [flightSchoolsLoading, setFlightSchoolsLoading] = useState(true);
  const [flightSchoolEditing, setFlightSchoolEditing] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const loadFlightSchools = useCallback(async () => {
    setFlightSchoolsLoading(true);
    await getFlightSchools()
      .then((response) => {
        if (response.success) {
          setFlightSchools(response.flightSchools);
        } else {
          toastr.error(response.error);
        }
        setFlightSchoolsLoading(false);
      })
      .catch((error) => toastr.error(error));
  }, []);

  const handleClose = useCallback(() => {
    setFlightSchoolEditing(null);
    setModalOpen(false);
  }, []);

  useEffect(() => {
    loadFlightSchools();
  }, [loadFlightSchools]);

  return (
    <>
      <Box
        className="w-full flex flex-grow flex-col overflow-y-auto"
        sx={{
          padding: { xs: 1, md: 3 },
          backgroundColor: "background.default",
        }}
      >
        <Card
          sx={{
            mr: "auto",
            ml: { xs: "auto", md: 0 },
            mt: { xs: 2, md: 0 },
            p: 3,
            gap: { xs: 2, md: 5 },
            flexDirection: "row",
          }}
        >
          <Typography
            className="text-start"
            level="h4"
            sx={{ fontSize: "xl", fontWeight: "md" }}
          >
            Flugschulen
          </Typography>
          <Button
            variant="soft"
            endDecorator={<AddIcon />}
            color="success"
            onClick={() => {
              setFlightSchoolEditing(null);
              setModalOpen(true);
            }}
          >
            Erstellen
          </Button>
        </Card>
        <Card sx={{ my: "1.5rem" }} className="flex flex-wrap gap-4">
          {flightSchoolsLoading ? (
            <FlightSchoolsTable showSkeletons />
          ) : (
            <FlightSchoolsTable
              flightSchools={flightSchools}
              setFlightSchoolsLoading={setFlightSchoolsLoading}
              setFlightSchoolEditing={setFlightSchoolEditing}
              handleModalOpen={setModalOpen}
            />
          )}
        </Card>
      </Box>
      <FlightSchoolModal
        flightSchool={flightSchoolEditing}
        open={modalOpen}
        handleClose={handleClose}
        loadFlightSchools={loadFlightSchools}
      />
    </>
  );
};

export default FlightSchools;
