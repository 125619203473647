import { Box, Card, Tab, TabList, TabPanel, Tabs, Typography } from "@mui/joy";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Table,
  TableBody,
  TableHead,
  TableRow,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import toastr from "toastr";

import { getFlightSchool, getMembers } from "../../../handlers/flightSchool";
import LoadingSpinner from "../../helpers/LoadingSpinner";
import FlightsTable from "../flight/Table";
import ConditionalTableCell from "../../helpers/ConditionalTableCell";
import moment from "moment";

const StudentsPanel = ({ students }) => {
  return (
    <Box
      sx={{ "&& :before": { display: "none" } }}
      className="flex flex-col gap-2"
    >
      {students.map((student) => (
        <Accordion
          sx={{
            backgroundColor: "transparent",
            boxShadow:
              "0 0 0 1px rgba(0, 0, 0, 0.05), 0 1px 2px 0 rgba(0, 0, 0, 0.1)",
            borderRadius: "0.5rem",
          }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon sx={{ height: "2.5rem", width: "2.5rem" }} />
            }
          >
            <Box
              key={student.id}
              className="flex flex-grow gap-2 p-2 items-center flex-wrap"
              sx={{ flexDirection: "row" }}
            >
              <Box className="flex flex-col flex-grow basis-1">
                <Typography level="h6">
                  {student.firstname} {student.lastname}
                </Typography>
                <Typography level="h7">{student.email}</Typography>
              </Box>
              <Box className="flex flex-row gap-3 flex-grow basis-1">
                <Box className="flex flex-col">
                  <Typography level="h7">Flüge:</Typography>
                  <Typography level="h7">Flugdauer:</Typography>
                  <Typography level="h7">Distanz:</Typography>
                </Box>
                <Box className="flex flex-col">
                  <Typography level="h7">
                    {student.flights?.length || "Unbekannt"}
                  </Typography>
                  <Typography level="h7">
                    {student.flightDuration
                      ? moment
                          .utc(
                            moment
                              .duration(student.flightDuration, "minutes")
                              .as("milliseconds"),
                          )
                          .format("HH:mm")
                      : "-"}
                  </Typography>
                  <Typography level="h7">
                    {student.distance
                      ? Math.round(parseFloat(student.distance) / 100) / 10 +
                        " km"
                      : "-"}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Box className="flex flex-col gap-2">
              <Typography level="h5">Flüge</Typography>
              <Box className="flex flex-col gap-2">
                <FlightsTable
                  flights={student.flights}
                  readOnly
                  setFlightEditing={() => {}}
                  handleModalOpen={() => {}}
                />
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

const StudentsTable = ({ students }) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <ConditionalTableCell showOnDesktop>Email</ConditionalTableCell>
          <ConditionalTableCell showOnMobile>Vorname</ConditionalTableCell>
          <ConditionalTableCell showOnMobile>Nachname</ConditionalTableCell>
          <ConditionalTableCell showOnMobile>Flüge</ConditionalTableCell>
          <ConditionalTableCell showOnTablet>Flugdauer</ConditionalTableCell>
          <ConditionalTableCell showOnDesktop>Distanz</ConditionalTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {students.map((student, index) => (
          <TableRow
            key={index}
            sx={{
              cursor: "pointer",
              ":hover": {
                backgroundColor: "rgba(0, 0, 0, 0.05)",
              },
            }}
          >
            <ConditionalTableCell showOnDesktop>
              {student.email}
            </ConditionalTableCell>
            <ConditionalTableCell showOnMobile>
              {student.firstname}
            </ConditionalTableCell>
            <ConditionalTableCell showOnMobile>
              {student.lastname}
            </ConditionalTableCell>
            <ConditionalTableCell showOnMobile>
              {student.flights?.length || "Unbekannt"}
            </ConditionalTableCell>
            <ConditionalTableCell showOnTablet>
              {student.flightDuration
                ? moment
                    .utc(
                      moment
                        .duration(student.flightDuration, "minutes")
                        .as("milliseconds"),
                    )
                    .format("HH:mm")
                : "-"}
            </ConditionalTableCell>
            <ConditionalTableCell showOnDesktop>
              {student.distance
                ? Math.round(parseFloat(student.distance) / 100) / 10 + " km"
                : "-"}
            </ConditionalTableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

const TeachersPanel = ({ teachers }) => {
  return (
    <Box
      sx={{ "&& :before": { display: "none" } }}
      className="flex flex-col gap-2"
    >
      {teachers.map((teacher) => (
        <Accordion
          sx={{
            backgroundColor: "transparent",
            boxShadow:
              "0 0 0 1px rgba(0, 0, 0, 0.05), 0 1px 2px 0 rgba(0, 0, 0, 0.1)",
            borderRadius: "0.5rem",
          }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon sx={{ height: "2.5rem", width: "2.5rem" }} />
            }
          >
            <Box
              key={teacher.id}
              className="flex flex-grow gap-2 p-2 items-center flex-wrap"
              sx={{ flexDirection: "row" }}
            >
              <Box className="flex flex-col flex-grow basis-1">
                <Typography level="h6">
                  {teacher.firstname} {teacher.lastname}
                </Typography>
                <Typography level="h7">{teacher.email}</Typography>
              </Box>
              <Box className="flex flex-col flex-grow basis-1">
                <Typography level="h7">
                  Schüler: {teacher.students?.length || "Unbekannt"}
                </Typography>
              </Box>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Box className="flex flex-col gap-2">
              <Typography level="h5">Schüler</Typography>
              <Box className="flex flex-col gap-2">
                <StudentsTable students={teacher.students} />
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

const FlightSchool = () => {
  const { flightSchoolId } = useParams();

  const [loading, setLoading] = useState(true);
  const [flightSchool, setFlightSchool] = useState(null);

  const [membersLoading, setMembersLoading] = useState(true);
  const [students, setStudents] = useState([]);
  const [teachers, setTeachers] = useState([]);

  const loadMembers = useCallback(async () => {
    setMembersLoading(true);
    await getMembers(flightSchoolId)
      .then((response) => {
        if (response.success) {
          const { students, teachers } = response.users;
          setStudents(students);
          setTeachers(teachers);
        } else {
          toastr.error(response.error);
        }
      })
      .catch((e) => toastr.error(e));
    setMembersLoading(false);
  }, [flightSchoolId]);

  const loadFlightSchool = useCallback(async () => {
    setLoading(true);
    await getFlightSchool(flightSchoolId)
      .then((response) => {
        if (response.success) {
          setFlightSchool(response.flightSchool);
          loadMembers();
        } else {
          toastr.error(response.error);
        }
      })
      .catch((e) => toastr.error(e));
    setLoading(false);
  }, [flightSchoolId, loadMembers]);

  useEffect(() => {
    loadFlightSchool();
  }, [loadFlightSchool]);

  if (loading) {
    return <LoadingSpinner />;
  }
  return (
    <Box
      className="w-full flex flex-grow flex-col overflow-y-auto"
      sx={{
        padding: { xs: 1, md: 3 },
        backgroundColor: "background.default",
      }}
    >
      <Card
        sx={{
          mt: { xs: 2, md: 0 },
          p: 3,
          gap: { xs: 2, md: 5 },
        }}
        className="flex"
      >
        <Box className="flex justify-center">
          {flightSchool.website ? (
            <a href={flightSchool.website} target="_blank" rel="noreferrer">
              <Typography
                level="h4"
                align="center"
                sx={{
                  textDecoration: "underline",
                  "&:hover": { color: "secondary.main" },
                }}
              >
                {flightSchool.name}
              </Typography>
            </a>
          ) : (
            <Typography level="h4" align="center">
              {flightSchool.name}
            </Typography>
          )}
        </Box>
        <Tabs defaultValue={0}>
          <TabList variant="soft" color="primary">
            <Tab>Schüler</Tab>
            <Tab>Lehrer</Tab>
          </TabList>
          <TabPanel value={0}>
            {membersLoading ? (
              <LoadingSpinner />
            ) : (
              <StudentsPanel students={students} />
            )}
          </TabPanel>
          <TabPanel value={1}>
            {membersLoading ? (
              <LoadingSpinner />
            ) : (
              <TeachersPanel teachers={teachers} />
            )}
          </TabPanel>
        </Tabs>
      </Card>
    </Box>
  );
};

export default FlightSchool;
