import { Box } from "@mui/joy";
import { extendTheme as extendJoyTheme } from "@mui/joy/styles";
import {
  Experimental_CssVarsProvider as CssVarsProvider,
  experimental_extendTheme as extendMuiTheme,
} from "@mui/material/styles";
import { deepmerge } from "@mui/utils";
import { useContext } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { responsiveFontSizes } from "@mui/material/styles";

import NavBar from "./components/layout/NavBar";
import Sidenav from "./components/layout/Sidenav";
import LoadingSpinner from "./components/helpers/LoadingSpinner";
import { UserContext } from "./contexts/User";
import {
  adminRoutes,
  teacherRoutes,
  privateRoutes,
  publicRoutes,
  userRoutes,
} from "./handlers/routes";

const joyTheme = extendJoyTheme({
  cssVarPrefix: "mui",
  fontFamily: "Nunito Sans, sans-serif",
  variants: {
    solid: {
      primary: {
        color: "#FFFFFF",
        backgroundColor: "#262B40",
      },
    },
    solidHover: {
      primary: {
        color: "#FFFFFF",
        backgroundColor: "#2E3650",
      },
    },
    solidActive: {
      primary: {
        color: "#FFFFFF",
        backgroundColor: "#2E3650",
        borderColor: "#4C5680",
      },
    },
    solidDisabled: {
      primary: {
        backgroundColor: "#83889d",
      },
    },
    soft: {
      primary: {
        color: "#262B40",
      },
    },
  },
  components: {
    JoyCircularProgress: {
      styleOverrides: {
        track: {
          stroke: "#83889d",
        },
      },
    },
  },
});

const muiTheme = extendMuiTheme({
  palette: {
    primary: {
      main: "#262B40",
    },
    secondary: {
      main: "#4478a6",
    },
    background: {
      default: "#F5F8FB",
      paper: "#F5F8FB",
    },
  },
  typography: {
    fontFamily: "Nunito Sans, sans-serif",
  },
});

const theme = responsiveFontSizes(deepmerge(joyTheme, muiTheme));

const App = () => {
  const { loading, user } = useContext(UserContext);

  return (
    <CssVarsProvider theme={theme}>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <Router>
          <Routes>
            {publicRoutes.map((route, index) => (
              <Route key={index} path={route.path} element={route.component} />
            ))}

            {user.loggedIn &&
              [
                ...privateRoutes,
                ...userRoutes,
                ...(user?.type === "teacher" || user?.type === "admin"
                  ? teacherRoutes
                  : []),
                ...(user?.permissions?.canManageFlightSchools
                  ? adminRoutes
                  : []),
              ].map((route, index) => (
                <Route
                  key={index}
                  path={route.path}
                  element={
                    <Box className="flex">
                      <Sidenav />
                      <Box className="w-full">
                        <NavBar />
                        <Box
                          sx={{
                            height: "calc(100vh - 64px)",
                            overflowY: "auto",
                            overflowX: "hidden",
                            display: "flex",
                          }}
                        >
                          {route.component}
                        </Box>
                      </Box>
                    </Box>
                  }
                />
              ))}
          </Routes>
        </Router>
      )}
    </CssVarsProvider>
  );
};

export default App;
