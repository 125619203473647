import { TableCell } from "@mui/material";

const ConditionalTableCell = ({
  children,
  showOnMobile,
  showOnTablet,
  showOnDesktop,
  ...props
}) => {
  return (
    <TableCell
      sx={{
        display: {
          xs: showOnMobile ? "table-cell" : "none",
          sm: showOnMobile || showOnTablet ? "table-cell" : "none",
          md:
            showOnMobile || showOnTablet || showOnDesktop
              ? "table-cell"
              : "none",
        },
      }}
      {...props}
    >
      {children}
    </TableCell>
  );
};

export default ConditionalTableCell;
