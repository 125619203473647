import APIHandler from "./apiHandler";

export async function getFlightSchools() {
  return APIHandler.get({ path: "/flightSchools" });
}
export async function getFlightSchool(id) {
  return APIHandler.get({ path: `/flightSchools/${id}` });
}
export async function createFlightSchool(flight) {
  return APIHandler.post({ path: "/flightSchools", data: flight });
}
export async function updateFlightSchool(id, flight) {
  return APIHandler.put({ path: `/flightSchools/${id}`, data: flight });
}
export async function deleteFlightSchool(id) {
  return APIHandler.delete({ path: `/flightSchools/${id}` });
}

export async function getMembers(id) {
  return APIHandler.get({ path: `/flightSchools/${id}/members` });
}
