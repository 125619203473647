import { Box } from "@mui/joy";
import {
  Divider,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from "@mui/material";
import { useContext } from "react";
import { Link } from "react-router-dom";

import { UserContext } from "../../contexts/User";
import {
  adminRoutes,
  privateRoutes,
  teacherRoutes,
} from "../../handlers/routes";
import RoleSelector from "../helpers/RoleSelector";

import LogoIcon from "../../icons/LogoIcon";

const textColor = "white";
const MenuItem = ({ route }) => {
  return (
    <ListItemButton component={Link} to={route.path}>
      <ListItemIcon
        style={{ color: textColor, display: "flex", justifyContent: "center" }}
      >
        {route.icon}
      </ListItemIcon>
      <ListItemText
        primary={route.name}
        sx={{
          [`& .MuiTypography-root`]: {
            color: textColor,
          },
        }}
      />
    </ListItemButton>
  );
};

const Sidenav = () => {
  const { user } = useContext(UserContext);

  const drawerWidth = 200;
  return (
    <Drawer
      variant="permanent"
      sx={{
        display: { xs: "none", md: "block", zIndex: 1 },
        width: drawerWidth,
        [`& .MuiDrawer-paper`]: {
          width: drawerWidth,
          border: 0,
          backgroundColor: "primary.main",
        },
      }}
    >
      <Toolbar
        style={{ paddingTop: 16 }}
        className="flex justify-center text-white"
      >
        <Link to="/" className="flex-grow flex justify-center">
          <LogoIcon className="flex-grow m-2" height="100%" />
        </Link>
      </Toolbar>
      <Box className="flex justify-between flex-col flex-grow">
        <List>
          {privateRoutes
            .filter((route) => route.name)
            .map((route, index) => (
              <MenuItem route={route} key={index} />
            ))}
          {(user?.type === "teacher" || user?.type === "admin") &&
            teacherRoutes
              .filter((route) => route.name)
              .map((route, index) => <MenuItem route={route} key={index} />)}
          <Divider />
        </List>
        <Box>
          <Box className="m-2">
            <RoleSelector />
          </Box>
          {user?.type === "admin" && (
            <List sx={{ pb: 0 }}>
              <Divider sx={{ backgroundColor: "white" }} />
              {adminRoutes
                .filter((route) => route.name)
                .map((route, index) => (
                  <MenuItem route={route} key={index} />
                ))}
            </List>
          )}
        </Box>
      </Box>
    </Drawer>
  );
};

export default Sidenav;
