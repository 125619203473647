import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import { Box, useTheme } from "@mui/joy";
import { useState } from "react";
import { useMediaQuery } from "@mui/material";

const ActionDropdown = ({ actions, ...props }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Box
      className="flex h-full p-1 justify-center items-center relative"
      sx={{}}
      {...props}
    >
      <SpeedDial
        ariaLabel="open-speed-dial-menu"
        sx={{
          "& #open-speed-dial-menu-actions": {
            position: "absolute",
            marginTop: { xs: "1rem", sm: "0" },
            marginRight: { xs: "0", sm: "1rem" },
          },
        }}
        icon={<SpeedDialIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        direction={
          useMediaQuery(theme.breakpoints.down("sm")) ? "down" : "left"
        }
        open={open}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            sx={{ opacity: action.hidden ? 0.4 : 1 }}
            tooltipTitle={action.name}
            onClick={async (e) => {
              if (action.onClick && !action.hidden) {
                action.onClick(e).then(() => {
                  handleClose();
                });
              } else {
                handleClose();
              }
            }}
          />
        ))}
      </SpeedDial>
    </Box>
  );
};

export default ActionDropdown;
