import { Box, Card, Typography, CardCover, CardContent } from "@mui/joy";
import { useContext } from "react";

import { UserContext } from "../../contexts/User";

const Home = () => {
  const { user } = useContext(UserContext);

  //tmp var for testing
  const weatherData = {
    dest: "Kitzbühel",
    temp: 26,
    weather: "Regen",
    wind: 10.67,
    humidity: 80,
    feltTemp: 23.85,
    visibility: 8.54,
    maxTemp: 29,
    minTemp: 19,
  };

  return (
    <>
      <Box
        className="w-full flex flex-grow flex-col overflow-y-auto"
        sx={{
          padding: { xs: 1, md: 3 },
          backgroundColor: "background.default",
        }}
      >
        <Card
          sx={{
            mr: "auto",
            ml: { xs: "auto", md: 0 },
            mt: { xs: 2, md: 0 },
            mb: 2,
            p: 3,
            gap: { xs: 2, md: 5 },
            flexDirection: "row",
          }}
        >
          <Typography level="h4" align="center" sx={{ marginBottom: "0rem" }}>
            Aktuelle Wetterdaten
          </Typography>
        </Card>
        <Box
          className="w-full flex flex-grow flex-row justify-between gap-4"
          sx={{
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <Card
            sx={{
              "&& :before": { display: "none" },
            }}
            className="w-full flex flex-wrap gap-3 p-2 items-center justify-center"
          >
            <Typography
              className="text-center"
              level="h5"
              sx={{ fontSize: "xl", fontWeight: "md" }}
            >
              {weatherData.dest}
            </Typography>
            <Typography
              className="text-center"
              level="h3"
              sx={{ fontSize: "xl", fontWeight: "md" }}
            >
              {weatherData.temp}°C
            </Typography>
            <Typography
              className="text-center"
              level="h6"
              sx={{ fontSize: "xl", fontWeight: "md" }}
            >
              {weatherData.weather}
            </Typography>
          </Card>
          <Card
            sx={{
              "&& :before": { display: "none" },
            }}
            className="w-full"
          >
            <Box className="w-full h-full flex flex-row gap-4">
              <Card
                style={{
                  backgroundColor: "#2E3650",
                  color: "#FFFFFF",
                }}
                className="w-full gap-2 p-2 flex flex-col justify-around items-center"
              >
                <Typography textColor="#FFFFFF">gefühlt:</Typography>
                <Typography textColor="#FFFFFF">Feuchtigkeit:</Typography>
                <Typography textColor="#FFFFFF">Wind:</Typography>
                <Typography textColor="#FFFFFF">Sicht:</Typography>
                <Typography textColor="#FFFFFF">Max:</Typography>
                <Typography textColor="#FFFFFF">Min:</Typography>
              </Card>
              <Box className="w-full gap-2 p-2 flex flex-col justify-around items-start">
                <Typography>{weatherData.feltTemp} °C</Typography>
                <Typography>{weatherData.humidity}%</Typography>
                <Typography>{weatherData.wind} km/h</Typography>
                <Typography>{weatherData.visibility} km</Typography>
                <Typography>{weatherData.maxTemp} °C</Typography>
                <Typography>{weatherData.minTemp} °C</Typography>
              </Box>
            </Box>
          </Card>
          <Card
            sx={{
              "&& :before": { display: "none" },
            }}
            className="w-full flex flex-wrap gap-3 p-2 items-center justify-center"
          >
            <CardCover style={{ filter: "blur(5px)" }}>
              <img
                src="https://assets.bwbx.io/images/users/iqjWHBFdfxIU/iVT.ESarqO6Y/v0/-999x-999.gif"
                loading="lazy"
                alt="map"
              />
            </CardCover>
            <CardContent>
              <Typography
                level="h6"
                fontWeight="lg"
                textColor="#fff"
                className="text-center"
                mt={{ xs: 12, sm: 18 }}
              >
                Platzhalter für Wetterkartenausschnitt
              </Typography>
            </CardContent>
          </Card>
        </Box>
        <Box
          sx={{
            marginTop: "1.5rem",
            position: "relative",
            bottom: 0,
            p: 2,
            display: "flex",
            flexDirection: "row",
            justifyContent: { xs: "center", md: "end" },
            width: "100%",
          }}
        >
          <Typography>
            Impressum | Datenschutz | AGB | © 2023 KitzSoftware OG
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default Home;
