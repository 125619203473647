import { Box, Button, Divider, Modal, ModalDialog, Typography } from "@mui/joy";
import { useState } from "react";
import { WarningRounded as WarningRoundedIcon } from "@mui/icons-material";

const ConfirmDeleteModal = ({ onDelete, loading, text = "" }) => {
  const [open, setOpen] = useState(false);

  const handleDelete = () => {
    onDelete(() => setOpen(false));
  };

  return (
    <>
      <Button
        color="danger"
        variant="soft"
        className="flex-grow"
        loading={loading}
        onClick={() => setOpen(true)}
      >
        Entfernen
      </Button>
      <Modal
        aria-labelledby="alert-dialog-modal-title"
        aria-describedby="alert-dialog-modal-description"
        open={open}
        onClose={() => setOpen(false)}
      >
        <ModalDialog variant="outlined" role="alertdialog">
          <Typography
            id="alert-dialog-modal-title"
            component="h2"
            level="inherit"
            fontSize="1.25em"
            mb="0.25em"
            startDecorator={<WarningRoundedIcon />}
          >
            Achtung
          </Typography>
          <Divider sx={{ my: 2 }} />
          {text && (
            <Typography
              id="alert-dialog-modal-description"
              textColor="text.tertiary"
              mb={3}
            >
              {text}
            </Typography>
          )}
          <Box className="flex justify-between">
            <Button
              variant="plain"
              color="neutral"
              onClick={() => setOpen(false)}
            >
              Zurück
            </Button>
            <Button
              variant="solid"
              color="danger"
              loading={loading}
              autoFocus
              onClick={() => handleDelete()}
            >
              Löschen
            </Button>
          </Box>
        </ModalDialog>
      </Modal>
    </>
  );
};

export default ConfirmDeleteModal;
