import {
  Add as AddIcon,
  Close as CloseIcon,
  ExpandMore as ExpandMoreIcon,
  Link as LinkIcon,
  Numbers as NumbersIcon,
  QrCode2 as QrCodeIcon,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  IconButton,
  ListItemDecorator,
  Modal,
  ModalDialog,
  Tab,
  TabList,
  Tabs,
  Typography,
} from "@mui/joy";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { useCallback, useContext, useEffect, useState } from "react";
import toastr from "toastr";

import moment from "moment";
import { UserContext } from "../../../contexts/User";
import { getStudents, updateTask } from "../../../handlers/teacher";
import LoadingSpinner from "../../helpers/LoadingSpinner";
import FlightsTable from "../flight/Table";

const InviteStudentModal = ({ open, handleClose }) => {
  /*
  const [type, setType] = useState("link");
  const allowedTypes = ["link", "qr", "code"];

  const handleGenerateLink = useCallback(async () => {}, []);
  const handleGenerateQr = useCallback(async () => {}, []);
  const handleGenerateCode = useCallback(async () => {}, []);
  */

  const handleSubmit = useCallback(async (e) => {}, []);

  return (
    <Modal
      open={open}
      onClose={() => handleClose()}
      sx={{ overflowY: "scroll" }}
    >
      <ModalDialog
        sx={{
          width: {
            xs: "100%",
            sm: "80%",
            md: "60%",
            lg: "50%",
          },
          borderRadius: "md",
          p: 3,
          boxShadow: "lg",
          maxHeight: "100vh",
          overflowY: "scroll",
        }}
      >
        <Typography
          component="h2"
          level="inherit"
          fontSize="1.25em"
          mb="0.25em"
        >
          Schüler einladen
        </Typography>
        <IconButton
          color="danger"
          onClick={() => handleClose()}
          variant="soft"
          sx={{
            position: "absolute",
            top: "1.25rem",
            right: "1.5rem",
            p: 1,
          }}
        >
          <CloseIcon />
        </IconButton>
        <form onSubmit={(e) => handleSubmit(e)} className="mt-3">
          <Tabs defaultValue={0} sx={{ mb: 2, borderRadius: "lg" }}>
            <TabList>
              <Tab>
                <ListItemDecorator>
                  <LinkIcon />
                </ListItemDecorator>
                Link
              </Tab>
              <Tab>
                <ListItemDecorator>
                  <QrCodeIcon />
                </ListItemDecorator>
                QR-Code
              </Tab>
              <Tab>
                <ListItemDecorator>
                  <NumbersIcon />
                </ListItemDecorator>
                Code
              </Tab>
            </TabList>
          </Tabs>
        </form>
      </ModalDialog>
    </Modal>
  );
};

const TeacherView = () => {
  const { user } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [students, setStudents] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);

  //tmp var for testing
  const tmp_todoData = [
    {
      id: 1,
      title: "Aufgabe 1",
      description: "Beschreibung der Aufgabe 1",
      date: "2021-10-10",
    },
    {
      id: 2,
      title: "Aufgabe 2",
      description: "Beschreibung der Aufgabe 2",
      date: "2021-10-10",
    },
    {
      id: 3,
      title: "Aufgabe 3",
      description: "Beschreibung der Aufgabe 3",
      date: "2021-10-10",
    },
    {
      id: 4,
      title: "Aufgabe 4",
      description: "Beschreibung der Aufgabe 4",
      date: "2021-10-10",
    },
  ];
  //tmp var for testing
  const tmp_notificationData = [
    {
      id: 1,
      title: "Benachrichtigung 1",
      description: "Beschreibung der Benachrichtigung 1",
      date: "2021-10-10",
    },
    {
      id: 2,
      title: "Benachrichtigung 2",
      description: "Beschreibung der Benachrichtigung 2",
      date: "2021-10-10",
    },
    {
      id: 3,
      title: "Benachrichtigung 3",
      description: "Beschreibung der Benachrichtigung 3",
      date: "2021-10-10",
    },
    {
      id: 4,
      title: "Benachrichtigung 4",
      description: "Beschreibung der Benachrichtigung 4",
      date: "2021-10-10",
    },
  ];

  const loadStudents = useCallback(async () => {
    setLoading(true);
    await getStudents()
      .then((response) => {
        if (response.success) {
          setStudents(response.students);
        } else {
          toastr.error(response.error);
        }
      })
      .catch((error) => toastr.error(error));
    setLoading(false);
  }, []);

  const handleTaskClick = async ({ studentId, taskName, done }) => {
    return updateTask({ studentId, taskName, done })
      .then(async (response) => {
        if (response.success) {
          const studentsCopy = [...students];
          const student = studentsCopy.find(
            (student) => student.id === studentId,
          );
          const task = student.tasks.find((task) => task.name === taskName);
          task.done = done;
          setStudents(studentsCopy);
        } else {
          toastr.error(response.error);
        }
      })
      .catch((error) => toastr.error(error));
  };

  useEffect(() => {
    loadStudents();
  }, [user, loadStudents]);

  return (
    <>
      <Box
        className="w-full flex flex-grow flex-col overflow-y-auto gap-4"
        sx={{
          padding: { xs: 2, md: 3 },
          backgroundColor: "background.default",
        }}
      >
        <Card
          sx={{
            p: 3,
            flexDirection: "row",
            justifyContent: { xs: "center", sm: "space-between" },
            alignItems: "center",
            mr: { xs: "0", sm: "auto" },
            ml: { xs: "0", sm: "auto", md: "0" },
          }}
        >
          <Typography
            className="text-start"
            level="h4"
            sx={{ fontSize: "xl", fontWeight: "md" }}
          >
            Übersicht
          </Typography>
        </Card>
        <Box
          className="w-full flex  gap-4"
          sx={{ flexDirection: { xs: "column", sm: "row" } }}
        >
          <Card
            sx={{
              "&& :before": { display: "none" },
            }}
            className="w-full flex flex-wrap gap-4 p-2"
          >
            <Typography
              className="text-center"
              level="h5"
              sx={{ fontSize: "xl", fontWeight: "md" }}
            >
              To-Do
            </Typography>
            {loading ? (
              <LoadingSpinner className="mx-auto" />
            ) : (
              tmp_todoData.map((todo) => (
                <Card
                  sx={{
                    backgroundColor: "transparent",
                    boxShadow:
                      "0 0 0 1px rgba(0, 0, 0, 0.05), 0 1px 2px 0 rgba(0, 0, 0, 0.1)",
                    borderRadius: "0.5rem",
                  }}
                >
                  {todo.title}
                </Card>
              ))
            )}
          </Card>
          <Card
            sx={{
              "&& :before": { display: "none" },
            }}
            className="w-full flex flex-wrap gap-4"
          >
            <Typography
              className="text-center"
              level="h5"
              sx={{ fontSize: "xl", fontWeight: "md" }}
            >
              Benachrichtigungen
            </Typography>
            {loading ? (
              <LoadingSpinner className="mx-auto" />
            ) : (
              tmp_notificationData.map((notification) => (
                <Card
                  sx={{
                    backgroundColor: "transparent",
                    boxShadow:
                      "0 0 0 1px rgba(0, 0, 0, 0.05), 0 1px 2px 0 rgba(0, 0, 0, 0.1)",
                    borderRadius: "0.5rem",
                  }}
                >
                  {notification.title}
                </Card>
              ))
            )}
          </Card>
        </Box>
        <Card
          sx={{
            p: 3,
            mt: 4,
            gap: { xs: 3, md: 5 },
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            mr: { xs: "0", sm: "auto" },
            ml: { xs: "0", sm: "auto", md: "0" },
          }}
        >
          <Typography
            className="text-start"
            level="h4"
            sx={{ fontSize: "xl", fontWeight: "md" }}
          >
            Schüler
          </Typography>
          <Button
            variant="soft"
            endDecorator={<AddIcon />}
            color="success"
            onClick={() => setModalOpen(true)}
          >
            Einladen
          </Button>
        </Card>
        <Card
          sx={{
            p: 2,
            "&& :before": { display: "none" },
          }}
          className="flex flex-wrap gap-4"
        >
          {loading ? (
            <LoadingSpinner className="my-6 mx-auto" />
          ) : (
            students.map((student, index) => (
              <Accordion
                sx={{
                  backgroundColor: "transparent",
                  boxShadow:
                    "0 0 0 1px rgba(0, 0, 0, 0.05), 0 1px 2px 0 rgba(0, 0, 0, 0.1)",
                  borderRadius: "12px",
                }}
                key={index}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      sx={{ height: "2.5rem", width: "2.5rem" }}
                    />
                  }
                >
                  <Box
                    key={student.id}
                    className="flex flex-grow gap-2 p-2 items-center"
                    sx={{ flexDirection: "row" }}
                  >
                    <Box className="flex flex-col flex-grow basis-1">
                      <Box className="flex flex-row items-center gap-3 flex-wrap">
                        <Typography level="h7">
                          {student.firstname} {student.lastname}
                        </Typography>
                        <Typography level="body3">{`#${student.id}`}</Typography>
                      </Box>
                    </Box>
                    <Box className="flex flex-row gap-3 flex-grow basis-1">
                      <Box className="flex flex-col">
                        <Typography level="h7">Flüge:</Typography>
                        <Typography level="h7">Flugdauer:</Typography>
                        <Typography level="h7">Distanz:</Typography>
                      </Box>
                      <Box className="flex flex-col">
                        <Typography level="h7">
                          {student.flights?.length || "Unbekannt"}
                        </Typography>
                        <Typography level="h7">
                          {student.flightDuration
                            ? moment
                                .utc(
                                  moment
                                    .duration(student.flightDuration, "minutes")
                                    .as("milliseconds"),
                                )
                                .format("HH:mm")
                            : "-"}
                        </Typography>
                        <Typography level="h7">
                          {student.distance
                            ? Math.round(parseFloat(student.distance) / 100) /
                                10 +
                              " km"
                            : "-"}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Box className="flex flex-col gap-2 mb-5">
                    <Typography level="h5">Flüge</Typography>
                    <Box className="flex flex-col gap-2">
                      <FlightsTable
                        flights={student.flights}
                        readOnly
                        setFlightEditing={() => {}}
                        handleModalOpen={() => {}}
                      />
                    </Box>
                  </Box>
                  <Box className="flex items-start flex-col gap-2">
                    <Typography level="h5">Aufgaben</Typography>
                    <Box className="flex gap-2 flex-wrap">
                      {student.tasks?.map((task) => (
                        <Button
                          sx={{ borderRadius: "0.5rem", flexGrow: 1 }}
                          variant="soft"
                          color={task.done ? "success" : "danger"}
                          endDecorator={task.done ? "✓" : "✗"}
                          onClick={() =>
                            handleTaskClick({
                              studentId: student.id,
                              taskName: task.name,
                              done: !task.done,
                            })
                          }
                        >
                          {task.name}
                        </Button>
                      ))}
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
            ))
          )}
        </Card>
      </Box>
      <InviteStudentModal
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
      />
    </>
  );
};

export default TeacherView;
