const LogoIcon = (props) => {
  return (
    <svg
      viewBox="0 0 838.91 285.78"
      fill="currentColor"
      height="1em"
      width="1em"
      {...props}
    >
      <g id="Ebene_1" data-name="Ebene 1">
        <path
          d="M369.71,334.38q11.27,15,22.54,30l23.52-22.92Z"
          transform="translate(-2.98 -144.95)"
        />
        <path
          d="M479.76,334.38q-11.26,15-22.54,30L433.7,341.47Z"
          transform="translate(-2.98 -144.95)"
        />
      </g>
      <g id="Ebene_3" data-name="Ebene 3">
        <path
          d="M412.21,370.86,426,396.57q7.05-12.72,14.09-25.46L426,357Z"
          transform="translate(-2.98 -144.95)"
        />
        <path d="M496.3,424.71" transform="translate(-2.98 -144.95)" />
      </g>
      <g id="Ebene_2" data-name="Ebene 2">
        <path
          d="M3,145,65.06,207,343.64,334.38l-57.31-52.53Z"
          transform="translate(-2.98 -144.95)"
        />
        <path
          d="M108,247.63l56.1,51.9,254.78,110-51.88-44Z"
          transform="translate(-2.98 -144.95)"
        />
        <path
          d="M841.89,152.91Q810.86,184,779.81,215L501.23,342.34l57.31-52.53Z"
          transform="translate(-2.98 -144.95)"
        />
        <path
          d="M744.58,247.63l-56.1,51.9-254.78,110,51.88-44Z"
          transform="translate(-2.98 -144.95)"
        />
        <path
          d="M531.94,379.59,514.21,396,433.7,430.74l16.39-13.92Z"
          transform="translate(-2.98 -144.95)"
        />
        <path
          d="M320.59,379.59,338.32,396l80.51,34.75-16.39-13.92Z"
          transform="translate(-2.98 -144.95)"
        />
      </g>
    </svg>
  );
};

export default LogoIcon;
