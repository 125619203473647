import { botttsNeutral } from "@dicebear/collection";
import { createAvatar } from "@dicebear/core";
import { Menu as MenuIcon } from "@mui/icons-material";
import { Box, Typography } from "@mui/joy";
import {
  AppBar,
  IconButton,
  Menu,
  MenuItem,
  SvgIcon,
  Toolbar,
} from "@mui/material";
import { useContext, useMemo, useState } from "react";
import { Link } from "react-router-dom";

import { UserContext } from "../../contexts/User";
import {
  adminRoutes,
  privateRoutes,
  teacherRoutes,
  userRoutes,
} from "../../handlers/routes";
import LogoIcon from "../../icons/LogoIcon";

const NavBar = () => {
  const { user } = useContext(UserContext);

  const avatar = useMemo(() => {
    return createAvatar(botttsNeutral, { seed: user.email });
  }, [user]);

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar
      position="sticky"
      sx={{
        backgroundColor: { sx: "primary.main", md: "background.paper" },
        boxShadow: { sx: "0px 4px 4px rgba(0, 0, 0, 0.25)", md: "none" },
      }}
    >
      <Toolbar
        disableGutters
        sx={{
          display: "grid",
          gridTemplateColumns: { xs: "1fr 1fr 1fr", md: "1fr" },
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          className="flex justify-start items-center"
          sx={{ display: { md: "none" } }}
        >
          <IconButton size="large" onClick={handleOpenNavMenu} color="inherit">
            <MenuIcon sx={{ height: "1.75rem", width: "1.75rem" }} />
          </IconButton>
          <Menu
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: "block", md: "none" },
            }}
          >
            {privateRoutes.map((route, index) => (
              <MenuItem key={index} onClick={handleCloseNavMenu}>
                <Link className="flex flex-row gap-2" to={route.path}>
                  <SvgIcon>{route.icon}</SvgIcon>
                  <Typography textAlign="center">{route.name}</Typography>
                </Link>
              </MenuItem>
            ))}
            {(user?.type === "teacher" || user?.type === "admin") &&
              teacherRoutes.map((route, index) => (
                <MenuItem key={index} onClick={handleCloseNavMenu}>
                  <Link className="flex flex-row gap-2" to={route.path}>
                    <SvgIcon>{route.icon}</SvgIcon>
                    <Typography textAlign="center">{route.name}</Typography>
                  </Link>
                </MenuItem>
              ))}
            {user?.type === "admin" &&
              adminRoutes
                .filter((route) => route.name)
                .map((route, index) => (
                  <MenuItem key={index} onClick={handleCloseNavMenu}>
                    <Link className="flex flex-row gap-2" to={route.path}>
                      <SvgIcon>{route.icon}</SvgIcon>
                      <Typography textAlign="center">{route.name}</Typography>
                    </Link>
                  </MenuItem>
                ))}
          </Menu>
        </Box>
        <Box
          className="flex justify-center items-center"
          sx={{ display: { md: "none" } }}
        >
          <Link to="/">
            <LogoIcon height="3rem" width="4rem" />
          </Link>
        </Box>

        <Box
          sx={{
            paddingTop: { xs: 0, md: 1 },
            paddingRight: { xs: 0.8, md: 1.5 },
          }}
          className="flex justify-end pr-3"
        >
          <Box
            className="flex items-center gap-2"
            onClick={handleOpenUserMenu}
            sx={{ cursor: "pointer" }}
          >
            <IconButton sx={{ p: 0 }}>
              <Box
                sx={{
                  height: "2.5rem",
                  width: "2.5rem",
                  borderRadius: "50%",
                  overflow: "hidden",
                }}
                dangerouslySetInnerHTML={{ __html: avatar.toString() }}
              />
            </IconButton>
            <Typography
              sx={{
                display: { xs: "none", md: "block" },
                cursor: "pointer",
                fontWeight: "bold",
              }}
              level="body2"
            >
              {user.firstname} {user.lastname}
            </Typography>
          </Box>
          <Menu
            sx={{ mt: "45px" }}
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {userRoutes.map((route, index) => (
              <MenuItem key={index} onClick={handleCloseUserMenu}>
                <Link className="flex flex-row gap-2" to={route.path}>
                  <SvgIcon>{route.icon}</SvgIcon>
                  <Typography textAlign="center">{route.name}</Typography>
                </Link>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
