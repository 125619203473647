import { Button, Card, Typography, Box, TextField } from "@mui/joy";
import { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import toastr from "toastr";

import { registerUser } from "../handlers/user";

const Register = () => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    const { email, password, passwordConfirm, firstname, lastname } =
      Object.fromEntries(new FormData(e.target).entries());

    if (password !== passwordConfirm) {
      toastr.warning("Passwords do not match");
      return;
    }

    setLoading(true);
    await registerUser({ email, password, firstname, lastname })
      .then((response) => {
        if (response.success) {
          toastr.success(response.message || "Registrierung erfolgreich!");
        } else {
          toastr.error(response.error || "Registrierung fehlgeschlagen!");
        }
        setLoading(false);
      })
      .catch((error) => toastr.error(error.error || "Registration failed"));
  }, []);

  return (
    <Box
      className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center"
      sx={{ backgroundColor: "background.default", padding: { xs: 2, md: 4 } }}
    >
      <Card sx={{ padding: 4 }} onSubmit={handleSubmit}>
        <form className="flex flex-col gap-4">
          <Typography level="h4" align="center">
            Registrierung
          </Typography>
          <TextField
            name="email"
            label="Email"
            type="email"
            variant="outlined"
            required
          />
          <div className="flex flex-row gap-3 flex-wrap">
            <TextField
              required
              className="flex-grow"
              name="firstname"
              label="Vorname"
              variant="outlined"
            />
            <TextField
              className="flex-grow"
              name="lastname"
              label="Nachname"
              variant="outlined"
              required
            />
          </div>
          <TextField
            name="password"
            label="Passwort"
            variant="outlined"
            type="password"
            required
          />
          <TextField
            name="passwordConfirm"
            label="Passwort bestätigen"
            variant="outlined"
            type="password"
            required
          />
          <Button type="submit" loading={loading}>
            Registrieren
          </Button>
          <Link to="/login">
            <Typography
              align="center"
              level="body2"
              sx={{
                ":hover": {
                  color: "primary.main",
                },
              }}
            >
              Sie haben bereits einen Account?
            </Typography>
          </Link>
        </form>
      </Card>
    </Box>
  );
};

export default Register;
