import { Box, CircularProgress } from "@mui/joy";

const LoadingSpinner = ({ ...props }) => {
  return (
    <Box
      className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center"
      {...props}
    >
      <CircularProgress color="primary" />
    </Box>
  );
};

export default LoadingSpinner;
