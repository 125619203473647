export default class APIHandler {
  static #env = window.location.origin.includes("localhost")
    ? "dev"
    : window.location.origin.includes("staging")
    ? "staging"
    : "prod";

  static get #url() {
    return this.#env === "dev"
      ? "/api"
      : this.#env === "staging"
      ? "/api-staging"
      : "/api";
  }
  /**
   * GET HTTP request to the server
   * @param {string} path
   * @param {object} data
   * @returns {Promise<object>}
   */
  static async get({ path, data = {} }) {
    let dataArray = Object.keys(data).map((key) => {
      return `${key}=${data[key]}`;
    });

    const params = dataArray.length > 0 ? "?" + dataArray.join("&") : "";

    return APIHandler.doRequest({
      url: path + params,
      method: "get",
    });
  }

  /**
   * POST HTTP request to the server
   * @param {string} path
   * @param {object} data
   * @returns {Promise<object>}
   */
  static async post({ path, data }) {
    return this.doRequest({
      url: path,
      data,
      method: "post",
    });
  }

  /**
   * PUT HTTP request to the server
   * @param {string} path
   * @param {object} data
   * @returns {Promise<object>}
   */
  static async put({ path, data = {} }) {
    return this.doRequest({
      url: path,
      data,
      method: "put",
    });
  }

  /**
   * DELETE HTTP request to the server
   * @param {string} path
   * @param {object} data
   * @returns {Promise<object>}
   */
  static async delete({ path, data = {} }) {
    let dataArray = Object.keys(data).map((key) => {
      return `${key}=${data[key]}`;
    });

    const params = dataArray.length > 0 ? "?" + dataArray.join("&") : "";

    return this.doRequest({
      url: path + params,
      data,
      method: "delete",
    });
  }

  static async doRequest({ url, data, method }) {
    return new Promise(async (resolve, reject) => {
      await fetch(this.#url + (url[0] !== "/" ? "/" : "") + url, {
        method,
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
        credentials: "include",
      })
        .then(async (response) => {
          const data = await response.json();
          if (data?.redirect && !window.location.href.endsWith(data.redirect)) {
            window.location.href = data.redirect;
          } else {
            resolve(data);
          }
        })
        .catch((error) => reject(error.message || error));
    });
  }
}
